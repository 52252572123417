const state = () => ({
  count: 0,
  data: [],
  types: [],
});
const actions = {
  getKpiCount({ commit }, params) {
    return this.$api.getKpiCount(params)
      .then((data) => {
        commit('saveKpiCount', data.payload);
        return data.payload;
      });
  },

  getKpi({ commit }, params) {
    return this.$api.getKpi({ ...params })
      .then((data) => {
        commit('saveKpi', data.payload);
        return data;
      });
  },
  getKpiTypes({ commit },) {
    return this.$api.getKpiTypes()
      .then((data) => {
        commit('saveKpiTypes', data.payload);
        return data;
      });
  },
};
const mutations = {
  saveKpi(state, payload) {
    state.data = payload;
  },

  saveKpiCount(state, payload) {
    state.count = payload.count;
  },
  saveKpiTypes(state, payload) {
    state.types = payload.available_types;
  },
};

export default () => ({
  state: state(),
  actions,
  mutations,
});
