let notifyReady = () => {
};
const ready = new Promise((resolve) => {
  notifyReady = () => setTimeout(resolve, 10);
});
export default () => ({
  intercom: null,
  limitations: {},
  manager_info: null,
  postback_tokens: null,
  tag_manager_tokens: null,
  telegram_bot: null,
  reports: null,
  dimensions: null,
  dimensionsAll: null,
  dimensions_groups: null,
  grouped_dimensions: null,
  grouped_metrics: null,
  metrics: null,
  metrics_groups: null,
  metrics_visible_by_default: null,
  optional_sections: {},
  notifyReady,
  ready,
});
