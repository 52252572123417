export default {
  saveItems(state, data) {
    state.items = data.payload;
    state.count = data.misc.count;
  },
  saveMediaContent(state, payload) {
    state.mediacontent = payload;
  },
  saveSites(state, payload) {
    state.sites = payload;
  },
};
