<template>
  <section class="tag-manager">
    <table-card
      ref="datatable"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :actions="actions"
      :page.sync="page"
      :page-size.sync="pageSize"
      :rows="rows"
      :count="$store.state.trafficSource.tagsCount"
      :formaters="formaters"
      i18n-path="tagManager.tags"
      dont-fill-empty
      extend-height
      @fetch-data="fetchDataTable"
    >
      <div slot="header" class="ui-align-center ui-mobile-12">
        <button :class="['btn', 'btn-outline-primary', 'btn-sm', 'text-nowrap']" @click="back">
          &lt; {{ $t('profile.buttons.back') }}
        </button>

        <span :class="['table-card__header-title', 'ui-g-md', 'ui-m-md-l', 'ui-mobile-12']">{{
          $store.state.trafficSource.item.name
        }}</span>

        <div slot="actionsPrepend" class="ui-g-md">
          <tooltip
            class=" tooltip-btn-disabled"
            :title="$t('tooltip_limits.tadManager')"
            placement="bottom-left"
            :disabled="!addDisabled"
          >
            <div>
              <button
                slot="actionsPrepend"
                :class="['btn', 'btn-primary', 'btn-sm', 'ui-desktop-hide', 'text-nowrap']"
                :disabled="addDisabled"
                @click="addTag"
              >
                {{ $t('tagManager.card.addTag') }}
              </button>
            </div>
          </tooltip>
        </div>
      </div>

      <div slot="actionsPrepend" class="ui-mobile-12 pr-4">
        <tooltip
          class="tooltip-btn-disabled"
          :title="$t('tooltip_limits.tadManager')"
          placement="bottom-left"
          :disabled="!addDisabled"
        >
          <div>
            <button
              slot="actionsPrepend"
              class="btn btn-primary btn-sm ui-mobile-hide"
              :disabled="addDisabled"
              @click="addTag"
            >
              {{ $t('tagManager.card.addTag') }}
            </button>
          </div>
        </tooltip>
      </div>
    </table-card>

    <edit-tag ref="editTag" :passed-params="passedParams" :platforms="platforms" @success="fetchDataTable()" />
  </section>
</template>

<script>
import { pageSizeMixin, resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import Formaters from 'src/views/Dashboard/CustomReports/formaters';
import TableCard from 'src/components/Cards/TableCard.vue';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import Tooltip from 'src/components/Tooltip.vue';
import UrlMixinHooks from '../../../components/Query/url-mixin-hooks';
import UrlMixin from '../../../components/Query/url-mixin';
import InjectPlugins from '../../../components/Report/Components/inject-plugins';
import EditTag from './EditTag';
import Mobtable from '../../../components/Mobiletable/Mobtable';
import Mobpagination from '../../../components/Mobiletable/MobPagination';

const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = '/tag-manager';
const pageLimitProp = 'limit';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

const defaultSettings = {
  page: 1,
  pageSize,
  offset: 0,
  limit: pageSize,
  sort_column: 'created_at',
  sort_dir: 'desc',
};
const fetch = async (ctx, params) => {
  if (ctx.$_.isEmpty(params)) {
    params = {
      ...defaultSettings,
    };
  }

  const tagsParams = {
    traffic_source_id: ctx.$route.params.id,
    sort_column: params.sort_column || defaultSettings.sort_column,
    sort_dir: params.sort_dir || defaultSettings.sort_dir,
    limit: params.limit || defaultSettings.limit,
    offset: params.offset || defaultSettings.offset,
  };

  const tagsCount = ctx.$store.dispatch('getTagsCount', tagsParams);
  const tags = ctx.$store.dispatch('getTags', tagsParams);
  const trafficSource = ctx.$store.dispatch('getTrafficSource', ctx.$route.params.id);
  const templates = ctx.$store.dispatch('getTagTemplates');

  return await Promise.all([tagsCount, tags, templates, trafficSource]);
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultSettings,
  },
  params: Object.keys(defaultSettings),
  fetch,
  windowRefreshHook: true,
  sortByName: 'sort_column',
  sortDirName: 'sort_dir',
});

export default {
  name: 'TagManager',
  fetch,
  components: {
    Mobtable,
    EditTag,
    Mobpagination,
    TableCard,
    Tooltip,
  },
  mixins: [UrlMixinHooks, UrlMixin, InjectPlugins, pageSizeMixin, tableFlowMixin],
  data() {
    return {
      viewName,
      pageLimitProp,
      page: 1,
      limit: defaultSettings.limit,
      offset: defaultSettings.offset,
      sort_column: defaultSettings.sort_column,
      sort_dir: defaultSettings.sort_dir,
      loading: false,
      columns: [
        {
          name: 'created_at',
          align: 'left',
          sortable: 'custom',
          filterable: false,
          width: 155,
          format: 'date-time',
          source: 'i18n',
          i18nPath: 'reports',
        },
        {
          name: 'name',
          align: 'left',
          sortable: true,
          filterable: false,
          width: 250,
        },
        {
          name: 'template',
          align: 'left',
          sortable: false,
          filterable: false,
        },
        {
          name: 'platform',
          align: 'left',
          sortable: false,
          filterable: false,
          width: 120,
        },
      ],
      actions: {
        width: 80,
        name: () => this.$t('profile.apiKeys.table.actions'),
        operations: [
          {
            name: 'edit',
            component: (row, ctx) => {
              const { editTag } = this;
              const { id } = row;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click(e) {
                      e.cancelBubble = true;
                      editTag(id);
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-pen text-green',
                    },
                  }),
                ],
              );
              return ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-green',
                    placement: 'top',
                    content: this.$t('postbacks.card.edit'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
            },
          },
          {
            name: 'delete',
            component: (row, ctx) => {
              const { deleteTag } = this;
              const { id } = row;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click(e) {
                      e.cancelBubble = true;
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-trash-alt text-red',
                    },
                  }),
                ],
              );
              const elTooltip = ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-red',
                    placement: 'top',
                    content: this.$t('postbacks.card.delete'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
              return ctx.$createElement(
                'ac-popover',
                {
                  attrs: {
                    width: 250,
                    message: this.$t('tagManager.messages.deleteMessage'),
                    buttons: [
                      {
                        text: this.$t('mediaBuying.channels.cancel'),
                      },
                      {
                        text: this.$t('postbacks.card.delete'),
                        type: 'danger',
                        click: () => deleteTag(id),
                      },
                    ],
                  },
                },
                [elTooltip],
              );
            },
          },
        ],
      },
      formaters: Formaters,
      platforms: ['Landings', 'PWA', 'Web', 'iOS / Android'],
      listener: '',
    };
  },
  props: ['passedParams'],
  computed: {
    rows() {
      return this.$store.state.trafficSource.tags.map((t) => {
        const template = this.$store.state.misc.tagTemplates.find(tt => tt.id === t.tag_manager_template_id);
        const platform = this.platforms.find(p => p.toLowerCase() === t.embed_platform);
        return {
          id: t.id,
          name: t.name,
          created_at: t.created_at,
          template: (template || {}).name,
          platform,
        };
      });
    },
    addDisabled() {
      const { max_tags_per_traffic_source } = this.$store.state.settings.limitations;
      return max_tags_per_traffic_source !== null && this.$store.state.trafficSource.tagsCount >= max_tags_per_traffic_source;
    },
  },
  methods: {
    back() {
      if (localStorage.getItem('getBackUrlTrafficSource') === null) {
        this.$router.push('/dashboard/traffic-source');
      } else {
        this.$router.push(JSON.parse(localStorage.getItem('getBackUrlTrafficSource')));
      }
    },
    addTag() {
      if (!this.addDisabled) {
        this.$refs.editTag.open();
      }
    },
    editTag(id) {
      this.$refs.editTag.open(id);
    },
    deleteTag(id) {
      this.$store
        .dispatch('deleteTag', {
          data: { id },
          passedParams: {
            ...this.passedParams,
            traffic_source_id: this.$store.state.trafficSource.item.id,
          },
        })
        .then(() => {
          if (this.rows.length === 1 && this.page > 1) {
            this.page = this.page - 1;
          }

          this.fetchDataTable();
          this.$alert.success(this.$t('tagManager.messages.wasDeleted'));
        });
      document.removeEventListener('mouseup', this.listener);
    },
    clearTooltip() {
      document.addEventListener(
        'mouseup',
        (this.listener = () => {
          setTimeout(() => {
            const tooltip = document.querySelectorAll('.el-tooltip__popper');
            tooltip.forEach((e, i) => {
              tooltip[i].style.display = 'none';
            });
          }, 500);
        }),
      );
    },
  },
  created() {
    this.setFromQuery(this.passedParams);
    this.actions.operations.forEach(o => o.component && (o.component = o.component.bind(this)));
  },
  mounted() {
    this.clearTooltip();
  },
  watch: {
    '$i18n.locale': function () {
      this.$store.dispatch('getTagTemplates');
    },
  },
  activated() {
    this.clearTooltip();
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/trafficsources/tagmanager';
</style>
