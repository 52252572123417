<template>
  <section class="promo__codes-form-wrap media-buying-modal add-public-modal">
    <modal :show.sync="active" @close="close">
      <h5 slot="header" class="modal-title">
        <span class="incrementing-id">ID: {{ form.incrementing_id }}</span>
        {{ $t('mediaBuying.campaign.post') }} -
        <span
          :class="
            form.status === 'approved'
              ? 'text-green'
              : form.status === 'declined' || form.status === 'for_rework'
                ? 'text-red'
                : form.status === 'on_review'
                  ? 'text-orange'
                  : 'text-blue'
          "
        >
          {{ form.status && $t(`mediaBuying.posts.status.${form.status}`) }}
        </span>
      </h5>
      <template>
        <div>
          <form @keyup.enter="send">
            <div
              v-if="form.id && form.status !== 'draft'"
              class="comment-overflow"
            >
              <div
                class="comment-wrap__wrap"
                v-for="(comment, index) in comments"
                :key="index"
              >
                <div class="comment-wrap__mess">
                  <div
                    v-if="comment.author_type === 'admin'"
                    class="aff-text-left mb-8"
                  >
                    <span
                      class="comment-wrap__mess-text comment-wrap__mess-text-left"
                    >
                      <span class="date">
                        {{ comment.created_at | formatDate3 }}
                      </span>
                      <span class="comment-wrap__mess-text-row">{{
                        comment.content
                      }}</span>
                      <a
                        v-for="(file, ind) in comment.files"
                        :key="ind"
                        :href="file.download_url"
                        :download="file.filename"
                        target="_blank"
                        class="comment-wrap__mess-files mb-4 mr-4"
                      >
                        <span class="file-name">{{ file.filename }}</span>
                        <i class="fas fa-download pl-4"></i>
                      </a>
                    </span>
                  </div>
                  <div
                    v-if="comment.author_type === 'affiliate'"
                    class="aff-text-right text-right mb-8"
                  >
                    <span
                      :class="{ editable: editIndexComment === index }"
                      class="comment-wrap__mess-text comment-wrap__mess-text-right"
                    >
                      <span class="comment-wrap__content">
                        <span
                          v-if="
                            hideEditAndDelete(comment)
                          "
                          class="comment-wrap__control"
                        >
                          <span class="date">
                            {{ comment.created_at | formatDate3 }}
                          </span>
                          <i
                            v-if="editIndexComment !== index"
                            @click="editMessage(comment, index)"
                            class="fas fa-pen pl-4"
                          ></i>
                          <i
                            v-if="editIndexComment !== index"
                            @click="deleteMessage(comment, index)"
                            class="fas fa-times pl-4"
                          ></i>
                        </span>
                        <span class="comment-wrap__mess-text-row">{{
                          comment.content
                        }}</span>
                        <a
                          v-for="(file, ind) in comment.files"
                          :key="ind"
                          :href="file.download_url"
                          :download="file.filename"
                          target="_blank"
                          class="comment-wrap__mess-files mb-4 mr-4"
                        >
                          <span class="file-name-wrap">
                            <span class="file-name">{{ file.filename }}</span>
                            <i class="fas fa-download pl-4"></i>
                          </span>
                        </a>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="form.id && form.status !== 'draft'"
              class="comment-wrap__inn mt-8"
            >
              <div class="d-flex comment-wrap">
                <div>
                  <div class="form-group select-wrapper m-0 w-100">
                    <textarea
                      v-model="comment"
                      :placeholder="$t('mediaBuying.campaign.comment')"
                      class="form-control form-control-ta"
                    />
                  </div>
                  <button
                    v-if="flagEditComment"
                    class="btn btn-outline-danger btn-sm btn-icon ml-8"
                    @click.prevent="resetEdit"
                  >
                    <i class="fas fa-times text-red"></i>
                  </button>
                  <button
                    class="btn btn-outline-primary d-flex align-items-center justify-content-center btn-sm ml-8"
                    :disabled="isDisabledBtnComment || !comment"
                    @click.prevent="preloadImage"
                  >
                    <img
                      :src="require('@/assets/theme/icon/ok.svg')"
                      alt=""
                      height="12"
                    />
                    <span class="ml-4">{{
                      $t('mediaBuying.campaign.send')
                    }}</span>
                  </button>
                </div>
              </div>
              <div
                class="form-group select-wrapper file-wrap file-wrap-comment mb-0 mt-0"
              >
                <el-upload
                  :class="{
                    disabled:
                      fileListComment.length ===
                      $store.state.mediaBuying.uploadSettingsData
                        .max_files_per_request,
                  }"
                  class="upload-demo"
                  action=""
                  :on-remove="handleRemoveComment"
                  :on-change="onSuccessComment"
                  :file-list="fileListComment"
                  :auto-upload="false"
                  multiple
                >
                  <button
                    class="btn btn-outline-primary btn-sm btn-icon"
                    @click.prevent
                  >
                    <i class="fas fa-paperclip"></i>
                  </button>
                </el-upload>
                <span
                  v-if="errorFile.length"
                  class="error error-file text-danger text-left mt-2"
                >{{ errorFile[0] }}</span>
              </div>
            </div>
            <div class="select-wrapper mb-8">
              <label>{{ $t('mediaBuying.campaign.publication_period') }}</label>
              <span>{{ form.period }}</span>
            </div>
            <div class="d-flex mb-8">
              <div class="select-wrapper mr-32">
                <label>{{ $t('mediaBuying.campaign.sum') }}</label>
                <span>{{
                  currencyFormatter(
                    form.amount,
                    currentRow.currency_code,
                  )
                }}</span>
              </div>
            </div>
            <div
              class="select-wrapper urls-list mb-8"
              v-if="form.urls.length && form.urls[0] !== '' && form.urls[0].url !== ''"
            >
              <label>URLs</label>
              <div class="text-green" v-for="(url, key) in form.urls" :key="url.url + key">
                <img
                  :alt="form.platform_id"
                  :src="iconPlatform.find(e => e.id === (url.url_platform_id || url.platform_id).toLowerCase()).logo_base64"
                />
                <a :href="url.url" class="text-green" target="_blank"> {{ url.url }}</a>
              </div>
            </div>
            <div
              class="form-group select-wrapper file-wrap mb-0"
              v-if="form.files && form.files.length"
            >
              <label class="file-wrap__label">{{
                $t('mediaBuying.campaign.files')
              }}</label>
              <div class="file-wrap__images-wrap">
                <div
                  v-for="(item, index) in form.files"
                  :key="index"
                  class="file-wrap__images-wrap-sub"
                >
                  <span class="demonstration">{{ item.filename }}</span>
                  <el-image
                    :z-index="100002000"
                    :src="item.preview_url"
                    :preview-src-list="srcList"
                    :fit="'contain'"
                  >
                    <div slot="error" class="image-slot">
                      <i class="far fa-file"></i>
                      <a
                        :href="item.download_url"
                        :download="item.filename"
                        target="_blank"
                      >
                        <i class="fas fa-download"></i>
                      </a>
                    </div>
                  </el-image>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>
      <div slot="footer" class="text-right d-flex align-items-baseline">
        <a class="btn btn-outline-primary btn-sm" @click="close">
          {{ $t('offer.offerRequestSuccess.close') }}
        </a>
        <a
          class="btn btn-primary btn-sm ml-16"
          :disabled="loading"
          v-if="form.status === 'draft' || form.status === 'for_rework'"
          @click="edit"
        >
          <span v-if="!loading">{{ $t('profile.buttons.edit') }}</span>
          <i v-if="loading" class="fas fa-spinner spinner-hide" />
        </a>
      </div>
    </modal>
    <add-post ref="addPost" id="ShowPost" @addPost="update" />
  </section>
</template>

<script>
import { Modal } from 'src/components';
import AddPost from 'src/views/Dashboard/MediaBuying/Modal/AddPost';
import editMessages from './mixins/editMessages';
import formatCurrency from '@/mixins/format-currency';

export default {
  components: {
    Modal,
    AddPost,
  },
  mixins: [editMessages, formatCurrency],
  data() {
    return {
      fileListCommentBack: [],
      fileListDoubleComment: [],
      fileListComment: [],
      countFileComment: 0,
      comment: '',
      comments: [],
      formComment: {
        content: '',
        file_ids: [],
      },
      openImagePopup: [false],
      active: false,
      form: {
        id: '',
        incrementing_id: '',
        status: '',
        period: '',
        amount: '',
        platform_id: '',
        urls: [],
        files: [],
      },
      fileList: [],
      currentRow: {},
      loading: false,
    };
  },
  props: ['tableSorting'],
  computed: {
    iconPlatform() {
      return this.$store.state.misc.postPlatforms;
    },

    srcList() {
      return (
        this.form.files
          .filter(e => e.preview_url !== null)
          .map(e => e.preview_url) || []
      );
    },
  },
  methods: {
    open(row) {
      this.active = true;
      this.currentRow = this.$_.cloneDeep(row);
      this.form.id = row.id;
      this.getCommentsPayment(row.id);
      this.form.incrementing_id = row.incrementing_id;
      this.form.status = row.status;
      this.form.period = `${this.$moment(row.post_period_start).format('DD MMM YYYY HH:mm')
      } - ${
        this.$moment(row.post_period_end).format('DD MMM YYYY HH:mm')}`;
      this.form.amount = row.amount;
      this.form.platform_id = row.platform_name.text;
      this.form.urls = row.urls;
      this.form.files = row.files;
    },
    close() {
      this.active = false;
      this.reset();
    },
    reset() {
      this.resetEdit();
      this.form.id = '';
      this.comments = [];
      this.fileListComment = [];
      this.errorFile = [];
      this.comment = '';
      this.formComment = {
        content: '',
        file_ids: [],
      };
    },
    edit() {
      this.close();
      this.$refs.addPost.open(this.currentRow);
    },
    getComments() {
      this.getCommentsPayment(this.form.id);
    },
    postCommentsPayment(id) {
      this.formComment.content = this.comment;
      this.$store
        .dispatch('setPostComments', { id, ...this.formComment })
        .then(() => {
          this.formComment = {
            content: '',
            file_ids: [],
          };
          this.comment = '';
          this.getCommentsPayment(this.form.id);
        })
        .finally(() => {
          this.loading = false;
          this.isDisabledBtnComment = false;
        });
    },
    getCommentsPayment(id) {
      const [sort_column, sort_dir] = ['created_at', 'asc'];
      this.$store
        .dispatch('getPostComment', {
          id,
          sort_column,
          sort_dir,
        })
        .then((res) => {
          this.comments = res.payload;
          setTimeout(() => {
            const over = document.getElementsByClassName('comment-overflow')[0];
            if (over) over.scrollTop = 9999;
          }, 100);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.$emit('addPost', undefined, 'hard');
    },
    currencyFormatter(amount, code) {
      amount = (+amount).toFixed(2);
      const amountData = amount.split('.');
      const result = (+amountData[0]).toLocaleString();

      return `${this.getCurrencyLabel(code)}${result}.${amountData[1]}`;
    },
  },
};
</script>
