<template>
  <section
    class="public-table media-buying-ad"
    :class="{
      wavebg: loading,
    }"
  >
    <table-card
      ref="datatable"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :rows="rows"
      :page.sync="page"
      :page-size.sync="pageSize"
      :count="$store.state.mediaBuying.postsCount"
      :formaters="formaters"
      :actions="actions"
      :header="$t('mediaBuying.campaigns.posts')"
      is-static
      dont-fill-empty
      :disable-actions="isArchived"
      :has-pagination="true"
      placementTooltip="bottom"
      i18n-path="mediaBuying.posts"
      @fetch-data="fetchDataTable"
    >
      <div
        slot="header"
        :class="[
          'ui-d-flex',
          'ui-desktop-no-wrap',
          'ui-mobile-wrap',
          'ui-mobile-12',
        ]"
      >
        <table-card-search-header
          v-model="search"
          class="ui-g-md"
          @search="handleSearch"
        />

        <period-wrapper
          class="ui-g-md ui-mobile-12"
          :settings="period"
          default-all-time
          @update="period = $event"
          @change="changePeriod"
        />

        <ui-filters
          :use-filters="availableFilters"
          :filters="filters"
          :route="route"
          @input="inputFilter"
          @reset="reset"
        />
        <div
          class="checkbox payment-history__checkbox ui-mobile-12 ui-g-md"
        >
          <input
            v-model="actionRequired"
            id="checkbox1"
            type="checkbox"
            @change="fetchData"
          >
          <label
            class="checkbox-label checkbox-label__action-required"
            for="checkbox1"
          >
            {{ $t('mediaBuying.campaign.action_required') }}
          </label>
        </div>
      </div>
      <div slot="actionsPrepend" class="ui-g-md ui-mobile-12">
        <button
          class="btn btn-primary btn-sm ui-mobile-12"
          :disabled="isArchived"
          @click="addPost"
        >
          + {{ $t('mediaBuying.campaign.add_post') }}
        </button>
      </div>
    </table-card>

    <show-post ref="showPost" :tableSorting="sorting" @addPost="update" />
    <add-post ref="addPost" id="PostsTable" @addPost="update" />
  </section>
</template>

<script>
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import { pageSizeMixin } from 'src/views/Dashboard/Reports/page_size.js';
import AddPost from 'src/views/Dashboard/MediaBuying/Modal/AddPost';
import ShowPost from 'src/views/Dashboard/MediaBuying/Modal/ShowPost';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Formatters from 'src/views/Dashboard/CustomReports/formaters';
import SortBy from 'src/components/SortBy.vue';
import TableCard from 'src/components/Cards/TableCard.vue';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import TableCardSearchHeader from 'src/components/Cards/TableCardSearchHeader.vue';
import UiFilters from 'src/views/Dashboard/MediaBuying/UiFilters/UiFilters';
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper.vue';
import moment from 'moment';
import formatCurrency from '@/mixins/format-currency';

const fieldMap = {
  post_status: 'status',
  post_type: 'type',
  // period: 'post_period_start',
  post_sum: 'amount',
  status: 'post_status',
  type: 'post_type',
  post_period_start: 'period',
  amount: 'post_sum',
  payment_sum: 'amount_usd',
};

const classes = {
  approved: 'text-green',
  on_review: 'text-orange',
  declined: 'text-red',
  paid: 'text-green',
  processing: 'text-orange',
  not_paid: 'text-red',
  for_rework: 'text-red',
  failed: 'text-red',
  draft: 'text-blue',
};

const defaultParams = {
  prop: 'created_at',
  order: 'desc',

  typePosts: '',
  statusPosts: '',

  search: '',
  created_at_from: '',
  created_at_to: '',
  period: 2,

  page: 1,
  pageSize: 20,
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  sortByName: 'prop',
  sortDirName: 'order',
  fetchOnActivated: false,
  offset: 0,
  limit: defaultParams.pageSize,
});

export default {
  components: {
    SortBy,
    Mobtable,
    AddPost,
    ShowPost,
    TableCard,
    TableCardSearchHeader,
    UiFilters,
    PeriodWrapper,
  },

  mixins: [
    UrlMixin,
    UrlMixinHooks,
    InjectPlugins,
    pageSizeMixin,
    tableFlowMixin,
    formatCurrency,
  ],

  props: ['statusChannel', 'statusCampaign', 'activeTab', 'tableSorting', 'dataRoot'],

  data() {
    return {
      route: 'promoMaterials',
      period: { from: '', to: '', period: 2 },
      filters: this.getDefaultFilter(),
      availableFilters: ['statusPosts', 'typePosts'],
      typePost: '',
      status: '',

      statusFiltersSettings: {
        minimumResultsForSearch: Infinity,
      },

      activePopperCount: null,
      actionRequired: true,

      columns: [
        {
          name: 'incrementing_id',
          align: 'left',
          filterable: false,
          sortable: 'custom',
          width: 60,
          renderValue: v => `
                  <span>${v}</span>`,
        },
        {
          name: 'post_type',
          align: 'center',
          filterable: false,
          width: 95,
          renderValue: v => `<span class='${v === 'publication' ? 'text-green' : 'text-red'}'>
              ${this.$t(`mediaBuying.posts.type.${v}`)}
            </span>`,
        },
        {
          name: 'post_status',
          align: 'center',
          filterable: true,
          width: 100,
          class: 'call-alert',
          renderValue: (v, row) => `<span class='${classes[v]} wrap-red-alert' >
                              ${this.$t(`mediaBuying.posts.status.${v}`)}
                              <span class='text-red text-red-alert'>
                                ${row.needs_attention
    ? '<i class="fas fa-exclamation-circle pl-2"></i>'
    : ''
}
                              </span>
                            </span>
                              `,
        },
        {
          name: 'payment_sum',
          align: 'right',
          filterable: false,
          sortable: 'custom',
        },
        {
          name: 'period_line',
          align: 'left',
          filterable: false,
          width: 258,
          sortable: false,
          renderValue: (v, row) => `${moment(
            new Date(row.post_period_start),
            'YYYY-MM-DD HH:mm',
          ).format('DD MMM YYYY HH:mm')} - ${moment(
            new Date(row.post_period_end),
            'YYYY-MM-DD HH:mm',
          ).format('DD MMM YYYY HH:mm')}`,
        },
        {
          name: 'platform_name',
          align: 'left',
          filterable: false,
          customTooltip: false,
          width: 200,
          linkAppend: (item) => {
            if (item.platform_name.length > 1) {
              const spliceAddresses = this.$_.cloneDeep(item.platform_name);
              spliceAddresses.splice(0, 1);
              const addresses = spliceAddresses
                .map(platform => this.$createElement(
                  'div',
                  { class: 'el-dropdown-menu__item' },
                  [
                    this.$createElement(
                      'a',
                      {
                        attrs: {
                          target: '_blank',
                          href: platform.text,
                        },
                        class: 'dd-link-platform_name',
                      },
                      platform.text,
                    ),
                  ],
                ));
              const icon = this.$createElement(
                'img',
                {
                  attrs: {
                    src: item.platform_name[0].icon,
                    alt: item.platform_name[0].icon,
                  },
                  class: 'icon-platform',
                },
              );
              const ip = this.$createElement(
                'a',
                {
                  attrs: {
                    target: '_blank',
                    href: item.platform_name[0].text,
                  },
                  class: 'server-adresses-value',
                },
                item.platform_name[0].text,
              );
              const dropdown = this.$createElement(
                'el-dropdown',
                {
                  class: 'server-adresses',
                  props: {
                    trigger: 'click',
                    placement: 'bottom',
                  },
                  on: {
                    'visible-change': isOpened => (this.activePopperCount = isOpened ? item.id : null),
                  },
                },
                [
                  this.$createElement(
                    'div',
                    {
                      class: 'el-dropdown-link',
                    },
                    [
                      this.$createElement(
                        'span',
                        {
                          class: {
                            'server-adresses-count': true,
                            active: this.activePopperCount === item.id,
                          },
                        },
                        `+${spliceAddresses.length}`,
                      ),
                    ],
                  ),
                  this.$createElement(
                    'el-dropdown-menu',
                    {
                      class: 'server-adresses-dropdown ddgroup',
                    },
                    addresses,
                  ),
                ],
              );
              return this.$createElement('span',
                { class: 'server-adresses-value-wrap' },
                [
                  this.$createElement(
                    'span',
                    { class: 'server-adresses-value_left' },
                    [icon, ip],
                  ),
                  [dropdown],
                ]);
            }
            return item.platform_name.length === 1 && item.platform_name[0].text !== ''
              ? this.$createElement('span', [
                this.$createElement(
                  'img',
                  {
                    attrs: {
                      src: item.platform_name[0].icon,
                      alt: item.platform_name[0].icon,
                    },
                    class: 'icon-platform',
                  },
                ),

                this.$createElement(
                  'a',
                  {
                    attrs: { target: '_blank', href: item.platform_name[0].text },
                    class: 'server-adresses-value',
                  },
                  item.platform_name[0].text,
                )])
              : this.$createElement(
                'span',
                {},
                '–',
              );
          },
        },
        {
          name: 'created_at',
          align: 'left',
          sortable: 'custom',
          filterable: false,
          width: 155,
          format: 'date-time',
          source: 'i18n',
          i18nPath: 'reports',
        },
        {
          name: 'updated_at',
          align: 'left',
          sortable: 'custom',
          filterable: false,
          width: 155,
          format: 'date-time',
          source: 'i18n',
          i18nPath: 'reports',
        },
      ],
      actions: {
        width: 130,
        name: () => this.$t('profile.apiKeys.table.actions'),
        fixed: 'right',
        operations: [
          {
            name: 'watched',
            component: (row, ctx) => {
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click: () => {
                      this.markAction(row, row.needs_attention);
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: `fa-regular ${row.needs_attention ? 'fa-circle-exclamation-check' : 'fa-exclamation-circle'}`,
                    },
                  }),
                ],
              );
              return ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: `el-tooltip icon__wrapper ${row.needs_attention ? 'text-green' : 'text-red'} `,
                    placement: 'top',
                    content: row.needs_attention ? this.$t('tickets.header.markAsRead') : this.$t('tickets.header.markAsUnread'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
            },
          },
          {
            name: 'show',
            component: (row, ctx) => {
              const { showPost } = this;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click: async () => {
                      await ctx.$store.dispatch('getPost', row.id);
                      showPost({ ...ctx.$store.state.mediaBuying.post, platform_name: row.platform_name });
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-eye',
                    },
                  }),
                ],
              );
              return ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-green',
                    placement: 'top',
                    content: this.$t('mediaBuying.posts.show'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
            },
          },
          {
            name: 'edit',
            component: (row, ctx) => {
              const { addPost } = this;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click: async () => {
                      await ctx.$store.dispatch('getPost', row.id);
                      addPost({ ...ctx.$store.state.mediaBuying.post, platform_name: row.platform_name });
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-pen',
                    },
                  }),
                ],
              );
              return ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: `el-tooltip icon__wrapper text-green ${
                      row.status === 'draft' || row.status === 'for_rework'
                        ? ''
                        : 'disabled'
                    }`,
                    placement: 'top',
                    content: this.$t('mediaBuying.posts.edit'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
            },
          },
          {
            name: 'review',
            component: (row, ctx) => {
              const { changeStatus } = this;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click() {
                      changeStatus(row);
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-check-double',
                    },
                  }),
                ],
              );
              return ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: `el-tooltip icon__wrapper text-orange ${
                      row.status === 'draft' || row.status === 'for_rework'
                        ? ''
                        : 'disabled'
                    }`,
                    placement: 'top',
                    content: this.$t('mediaBuying.posts.submit_for_review'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
            },
          },
          {
            name: 'delete',
            component: (row, ctx) => {
              const disabled = row.status !== 'draft';
              const { deletePost } = this;
              const span = ctx.$createElement(
                'span',
                {
                  attrs: {
                    disabled,
                  },
                  on: {
                    click(e) {
                      e.cancelBubble = true;
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-trash-alt text-red',
                    },
                  }),
                ],
              );
              const elTooltip = ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: `el-tooltip icon__wrapper text-red ${
                      row.status !== 'draft' ? 'disabled' : ''
                    }`,
                    placement: 'top',
                    content: this.$t('promoCodes.promocodes.delete'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
              return ctx.$createElement(
                'ac-popover',
                {
                  attrs: {
                    width: 250,
                    message: this.$t(
                      'mediaBuying.campaigns.delete_post_message',
                    ),
                    buttons: [
                      {
                        text: this.$t('mediaBuying.channels.cancel'),
                      },
                      {
                        text: this.$t('promoCodes.promocodes.delete'),
                        type: 'danger',
                        click: () => deletePost(row),
                      },
                    ],
                    disabled,
                  },
                },
                [elTooltip],
              );
            },
          },
        ],
      },
      loading: false,
      formaters: Formatters,
    };
  },

  watch: {
    filters: {
      immediate: true,
      handler(value) {
        let _value = value;
        if (!value) {
          this.filter = this.getDefaultFilter();
          _value = this.filter;
        }

        this.typePosts = _value.typePosts;
        this.statusPosts = _value.statusPosts;
      },
    },

    activeTab(v) {
      if (v === 'posts') {
        this.update();
        this.$store.dispatch('getPostPlatforms', {});
      }
    },
  },

  computed: {
    iconPlatform() {
      return this.$store.state.misc.postPlatforms;
    },

    rows() {
      return this.$store.state.mediaBuying.posts.map(p => ({
        ...p,
        post_status: p.status,
        post_type: p.type,
        post_sum: this.currencyFormatter(p.amount, p.payments_currency_code),
        period: `${moment(new Date(p.post_period_start), 'YYYY-MM-DD HH:mm')
          .locale(this.$i18n.locale)
          .format('DD MMM YYYY HH:mm')}
                  - ${moment(new Date(p.post_period_end), 'YYYY-MM-DD HH:mm')
          .locale(this.$i18n.locale)
          .format('DD MMM YYYY HH:mm')}`,
        platform_name: p.urls.map(data => ({
          icon: this.iconPlatform.find(e => e.id === (data.url_platform_id || data.platform_id))?.logo_base64,
          text: data.url,
        })),
        payment_sum: this.currencyFormatter(p.amount, p.currency_code),
      }));
    },

    isArchived() {
      return this.statusCampaign === 'archived';
    },
  },
  methods: {
    changePeriod() {
      this.fetchData();
    },

    async inputFilter(data) {
      this.filters = data;
      this.page = 1;
      this.update();
    },

    reset() {
      this.filters = null;
      this.update();
    },

    initFilter(query) {
      const filters = this.$_.pick(query, ['typePosts', 'statusPosts']);
      const defaultFilters = this.$_.pick(defaultParams, ['typePosts', 'statusPosts']);

      this.filters = {
        ...defaultFilters,
        ...this.filters,
        ...filters,
      };
    },

    getDefaultFilter() {
      return {
        typePosts: defaultParams.typePosts,
        statusPosts: defaultParams.statusPosts,
      };
    },

    async markAction(row, action) {
      if (action) {
        await this.$api.postPostMarkAsRead(row.id);
        await this.$store.dispatch('getCampaignsAttentionCounters');
        this.update('silent', 'hard');
      } else {
        await this.$api.postPostMarkAsUnread(row.id);
        await this.$store.dispatch('getCampaignsAttentionCounters');
        this.update('silent', 'hard');
      }
    },

    async addPost(row) {
      const balanceEmpty = Object.values(this.$store.state.mediaBuying.campaign.available_spend || {})
        .every(el => el <= 0);

      const isEmptyDataRoot = this.$_.isEmpty(this.dataRoot);
      if (!balanceEmpty || row.id) {
        const available_spend_root = !isEmptyDataRoot
          ? this.$_.pick(this.dataRoot, ['available_spend'])
          : {};
        this.$refs.addPost.open({ ...row, ...available_spend_root });
        if (row.needs_attention) {
          await this.markAction(row, row.needs_attention);
        }
      } else {
        this.$alert.error(this.$t('mediaBuying.campaign.zero_balance_error'));
      }
    },

    async showPost(row) {
      this.$refs.showPost.open(row);
      if (row.needs_attention) {
        await this.markAction(row, row.needs_attention);
      }
    },

    changeStatus(row) {
      this.$store.dispatch('postToReview', row.id).then(() => {
        this.update();
        this.$alert.success(this.$t('mediaBuying.campaign.review_message'));
      });
    },

    async deletePost(row) {
      await this.$store.dispatch('deletePost', row.id);
      this.update('silent', 'hard');
    },

    update(action, hard) {
      const hardAction = hard === 'hard' ? { action: hard } : {};
      this.$emit('update', {
        ...hardAction,
        tab: 'posts',
        typeAction: action,
        prop: fieldMap[this.sorting.prop] || this.sorting.prop,
        order: this.sorting.order,

        search: this.search,
        status: this.filters?.statusPosts || '',
        type: this.filters?.typePosts || '',
        needs_attention_first: this.actionRequired,
        created_at_from: this.period.from,
        created_at_to: this.period.to,
        period: this.period.period,

        limit: this.pageSize,
        offset: (this.page - 1) * this.pageSize,
      });
    },
    async fetchData() {
      this.update();
    },
    currencyFormatter(amount, code) {
      amount = amount.toFixed(2);
      const amountData = amount.split('.');
      const result = (+amountData[0]).toLocaleString();

      return `${this.getCurrencyLabel(code)}${result}.${amountData[1]}`;
    },
  },
  created() {
    this.typePost = this.getUrlQueryParam('type');
    this.status = this.getUrlQueryParam('status');

    if (this.activeTab === 'posts') {
      this.$store.dispatch('getPostPlatforms', {});
    }

    this.actions.operations.map((e) => {
      if (e.component) {
        e.component = e.component.bind(this);
      }
    });
  },

  activated() {
    this.filters = {
      typePosts: '',
      statusPosts: '',
    };
  },
};
</script>
