<template>
  <section>
    <table-card
      ref="datatable"
      class="table"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :page.sync="page"
      :page-size.sync="pageSize"
      :rows="items"
      :count="totalCountRow"
      :header="$t('kpi.title')"
      dont-fill-empty
      extend-height
      @fetch-data="fetchDataTable({ type })"
    >
      <template slot="header">
        <div class="ui-align-center">
          <el-select
            v-model="type"
            class=""
            clearable
            filterable
            :placeholder="$t('kpi.selectKpiType')"
            @change="fetchDataTable({ type: $event })"
          >
            <el-option
              v-for="(item) in kpiTypeOptions"
              :key="item.key"
              :label="item.text"
              :value="item.key"
            />
          </el-select>
        </div>
      </template>
    </table-card>
  </section>
</template>

<script>
import numeral from 'numeral';
import formattedCurrency from 'src/helpers/formattedCurrency.js';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import SortBy from 'src/components/SortBy.vue';
import Formaters from 'src/views/Dashboard/CustomReports/formaters';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import TableCard from 'src/components/Cards/TableCard.vue';
import { pageSizeMixin, resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import formatCurrency from '@/mixins/format-currency';
import FilterReports from '@/components/Filter-reports/Filter-reports.vue';
import PeriodWrapper from '@/views/Dashboard/Reports/Filters/PeriodWrapper.vue';


const sizes = {
  1080: 15,
  1440: 25,
};
const viewName = '/kpi';
const pageLimitProp = 'pageSize';
const pageSize = resolvePageSize(viewName, {
  _default: 10,
  sizes,
});

const defaultParams = {
  totalRows: 0,
  pageSize,
  page: 1,
  sort_column: 'created_at',
  sort_dir: 'desc',
};

const fetch = (ctx, params) => {
  if (ctx.$_.isEmpty(params)) {
    params = {
      ...defaultParams,
    };
  }

  const query = ctx.params
    ? ctx.params.lastQuery
    : {};

  const count = () => ctx.$store.dispatch('getKpiCount', {
    sort_column: defaultParams.sort_column,
    sort_dir: defaultParams.sort_dir,
    limit: pageSize,
    ...query,
    ...params,
  });

  const kpi = () => ctx.$store.dispatch('getKpi', {
    sort_column: defaultParams.sort_column,
    sort_dir: defaultParams.sort_dir,
    limit: pageSize,
    ...query,
    ...params,
  });

  if (ctx.lastQuery) {
    ctx.lastQuery = {
      ...params,
      ...query,
    };
  }

  return Promise.all([count(), kpi()]);
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  fetch,
  sortByName: 'sort_column',
  sortDirName: 'sort_dir',
  windowRefreshHook: true,
});

export default {
  fetch,
  components: {
    PeriodWrapper, FilterReports,
    Mobtable,
    Mobpagination,
    SortBy,
    TableCard,
  },
  mixins: [
    UrlMixinHooks,
    UrlMixin,
    InjectPlugins,
    pageSizeMixin,
    HelpersMixin,
    tableFlowMixin,
    formatCurrency,
  ],
  props: ['fetchedData', 'passedParams'],
  data() {
    return {
      viewName,
      pageLimitProp,
      lastQuery: {},
      type: '',
      columns: [
        {
          name: 'incrementing_id',
          source: 'i18n',
          width: 36,
          i18n: 'reports.fields.incrementing_id.title',
        },
        {
          name: 'status',
          source: 'i18n',
          i18n: 'reports.fields.status.title',
          width: 90,
          computedClass: val => (this.statusMap[val] || {}).class,
          renderValue: (val) => {
            const { label = '' } = this.statusMap[val] || {};
            return label;
          },
        },
        {
          name: 'type',
          source: 'i18n',
          i18n: 'kpi.fields.type.title',
          renderValue: (val) => this.typesMap[val],
        },
        {
          name: 'goal',
          source: 'i18n',
          align: 'right',
          width: 70,
          format: 'number',
          i18n: 'kpi.fields.goal.title',
        },
        {
          name: 'rate',
          align: 'right',
          filterable: false,
          sortable: false,
          source: 'i18n',
          width: 100,
          i18n: 'kpi.fields.rate.title',
          renderValue: (val, row) => {
            if (row.bonus_payout_rate) {
              return `${row.bonus_payout_rate}%`;
            }

            return `${this.getCurrencyLabel(row.bonus_payout_currency_code)}${formattedCurrency(row.bonus_payout_sum)}`;
          },
        },

        {
          name: 'kpi_progress',
          source: 'i18n',
          headerAlign: 'right',
          align: 'right',
          filterable: false,
          sortable: false,
          width: 75,
          i18n: 'kpi.fields.progress.title',
          renderValue: (val, row) => `${numeral(row.progress).format('0,0')}%`,
        },
        {
          name: 'period',
          sortable: 'custom',
          source: 'i18n',
          width: 250,
          i18n: 'kpi.fields.period.title',
          renderValue: (_, row) => `${this.formatDate(row.period_start)} &ndash; ${this.formatDate(row.period_end)}`,
        },
        {
          name: 'created_at',
          sortable: 'custom',
          format: 'date-time',
          source: 'i18n',
          width: 130,
          i18n: 'reports.fields.created_at.title',
        },
      ],
      pagination: true,
      totalRows: 0,
      loading: false,
      formaters: Formaters,
    };
  },
  beforeMount() {
    this.pageSize = this.limit;
  },
  computed: {
    kpiTypeOptions() {
      return this.$store.state.kpi.types.map(el => ({
        key: el.type,
        text: el.title,
      }));
    },
    typesMap() {
      return this.$store.state.kpi.types.reduce((acc, el) => {
        acc[el.type] = el.title;
        return acc;
      }, {});
    },
    statusMap() {
      return {
        in_progress: {
          label: this.$t('kpi.status.in_progress'),
          class: 'text-orange',
        },
        pending: {
          label: this.$t('kpi.status.pending'),
        },
        failed: {
          label: this.$t('kpi.status.failed'),
          class: 'text-red',
        },
        completed: {
          label: this.$t('kpi.status.completed'),
          class: 'text-green',
        },
        canceled: {
          label: this.$t('kpi.status.canceled'),
          class: 'text-red',
        },
      };
    },
    cacheData() {
      const {
        totalRows, pageSize, page, sort_dir, sort_by, lastQuery, search, type
      } = this;
      return {
        totalRows,
        pageSize,
        page,
        sort_dir,
        sort_by,
        lastQuery,
        search,
        type,
      };
    },
    items() {
      return this.$store.state.kpi.data;
    },

    totalCountRow() {
      return this.$store.state.subaffiliates.count;
    },


  },
  created() {
    this.setFromQuery(this.passedParams);
  },

  watch: {
    '$i18n.locale': function () {
      this.$store.dispatch('getKpiTypes');
    },
  },
  methods: {
    formatDate(value) {
      return this.$moment(value).locale(this.$i18n.locale).format('DD MMM YYYY');
    },
    setFromQuery(params) {
      const {
        totalRows, pageSize, page, sort_dir, sort_by, lastQuery, type
      } = params;
      if (!totalRows || !pageSize || !page) {
        return;
      }
      this.totalRows = totalRows;
      this.pageSize = pageSize;
      this.page = page;
      this.sort_dir = sort_dir;
      this.sort_by = sort_by;
      this.lastQuery = lastQuery;
      this.type = type;
    },
  },
};
</script>

<style lang="scss" scoped>
.table /deep/ {
  .custom-header .text {
    padding-right: 0!important;
  }
}
</style>
<style lang="scss">
@import 'src/assets/theme/default/subaffiliates/subaffiliates';
</style>
