<template>
  <section
    class="public-table media-buying-ad"
    :class="{
      wavebg: loading
    }"
  >
    <table-card
      ref="datatable"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      sort-by-name="prop"
      sort-dir-name="order"
      :rows="rows"
      :page.sync="page"
      :page-size.sync="pageSize"
      :count="$store.state.promocode.totalRows"
      :formaters="formaters"
      :actions="actions"
      :header="$t('mediaBuying.campaigns.promo_codes')"
      is-static
      dont-fill-empty
      custom-context
      :disable-actions="isArchived"
      :has-pagination="true"
      placementTooltip="bottom"
      i18n-path="mediaBuying.promocodes"
      @fetch-data="fetchDataTable"
    >
      <div
        slot="header"
        :class="[
          'ui-d-flex',
          'ui-desktop-no-wrap',
          'ui-mobile-wrap',
          'ui-mobile-12',
        ]"
      >
        <table-card-search-header
          v-model="search"
          class="ui-g-md"
          @search="handleSearch"
        />
      </div>
      <div
        slot="actionsPrepend"
        class="ui-g-md ui-mobile-12"
      >
        <button
          class="btn btn-primary btn-sm ui-mobile-12"
          :disabled="isArchived"
          @click="addPromocode"
        >
          + {{$t('mediaBuying.campaigns.add_promocode')}}
        </button>
      </div>
    </table-card>
    <add-promo-code ref="addCode" @addPromoCode="update"/>
  </section>
</template>

<script>
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import { pageSizeMixin } from 'src/views/Dashboard/Reports/page_size.js';
import AddPromoCode from 'src/views/Dashboard/MediaBuying/Modal/AddPromoCode';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Formatters from 'src/views/Dashboard/CustomReports/formaters';
import SortBy from 'src/components/SortBy.vue';
import TableCard from 'src/components/Cards/TableCard.vue';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import TableCardSearchHeader from 'src/components/Cards/TableCardSearchHeader.vue';

const defaultParams = {
  prop: 'created_at',
  order: 'desc',
  search: '',

  page: 1,
  pageSize: 20,
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  sortByName: 'prop',
  sortDirName: 'order',
  fetchOnActivated: false,
  offset: 0,
  limit: defaultParams.pageSize,
});

export default {
  components: {
    SortBy,
    Mobtable,
    AddPromoCode,
    TableCard,
    TableCardSearchHeader,
  },
  mixins: [
    UrlMixin,
    UrlMixinHooks,
    InjectPlugins,
    pageSizeMixin,
    tableFlowMixin,
  ],
  props: ['statusChannel', 'statusCampaign', 'activeTab', 'tableSorting'],

  data() {
    return {
      period: {
        from: moment()
          .startOf('month')
          .format('YYYY-MM-DD 00:00:00'),
        to: moment()
          .endOf('day')
          .format('YYYY-MM-DD 23:59:59'),
        period: 30,
      },
      columns: [
        {
          name: 'created_at',
          align: 'left',
          sortable: 'custom',
          filterable: false,
          width: 155,
          format: 'date-time',
          source: 'i18n',
          i18nPath: 'reports',
        },
        {
          name: 'promo_code',
          align: 'left',
          filterable: false,
          width: 250,
          copyable: true,
        },
        {
          name: 'affiliate_id',
          align: 'left',
          filterable: false,
          renderValue: v => `<span class='text-green'>${v}</span>`,
        },
        {
          name: 'registrations',
          align: 'right',
          filterable: false,
          sortable: 'custom',
          width: 130,
        },
        {
          name: 'players',
          align: 'right',
          filterable: false,
          sortable: 'custom',
          width: 130,
        },
      ],
      loading: false,
      formaters: Formatters,
      copyDone: false,
    };
  },

  computed: {
    actions() {
      return {
        width: 100,
        name: () => this.$t('profile.apiKeys.table.actions'),
        fixed: 'right',
        operations: [
          {
            name: 'statistics',
            component: (row, ctx) => {
              row = _.cloneDeep(row);
              const { period, $router, compressUrlQuery } = this;
              const urlQuery = {};
              urlQuery.openPromocodeReport = row;
              urlQuery.passedPeriod = period;
              const span = ctx.$createElement('a', {
                on: {
                  click(e) {
                    e.cancelBubble = true;
                    $router.push({
                      name: 'routes.custom',
                      params: { urlQuery: compressUrlQuery(urlQuery) },
                    });
                  },
                },
                attrs: {
                  href: `#/dashboard/custom/${this.compressUrlQuery(urlQuery)}`,
                },
              }, [
                ctx.$createElement('i', {
                  attrs: {
                    class: 'far fa-chart-bar text-info',
                  },
                }),
              ]);
              return ctx.$createElement('el-tooltip', {
                attrs: {
                  class: 'el-tooltip icon__wrapper text-info',
                  placement: 'top',
                  content: this.$t('promoCodes.promocodes.statistics'),
                  'open-delay': 500,
                },
              }, [span]);
            },
          },
          {
            name: 'edit',
            component: (row, ctx) => {
              const { addPromocode } = this;
              const span = ctx.$createElement('span', {
                on: {
                  click(e) {
                    e.cancelBubble = true;
                    addPromocode(row);
                  },
                },
              }, [
                ctx.$createElement('i', {
                  attrs: {
                    class: 'far fa-pen text-green',
                  },
                }),
              ]);
              return ctx.$createElement('el-tooltip', {
                attrs: {
                  class: 'el-tooltip icon__wrapper text-green',
                  placement: 'top',
                  content: this.$t('postbacks.card.edit'),
                  'open-delay': 500,
                },
              }, [span]);
            },
          },
          {
            name: 'delete',
            component: (row, ctx) => {
              const { deleteKey } = this;
              const span = ctx.$createElement('span', {
                on: {
                  click(e) {
                    e.cancelBubble = true;
                  },
                },
              }, [
                ctx.$createElement('i', {
                  attrs: {
                    class: 'far fa-trash-alt text-red',
                  },
                }),
              ]);
              const elTooltip = ctx.$createElement('el-tooltip', {
                attrs: {
                  class: 'el-tooltip icon__wrapper text-red',
                  placement: 'top',
                  content: this.$t('promoCodes.promocodes.delete'),
                  'open-delay': 500,
                },
              }, [span]);
              return ctx.$createElement('ac-popover', {
                attrs: {
                  width: 250,
                  message: this.$t('mediaBuying.campaigns.delete_promocode_message'),
                  buttons: [
                    {
                      text: this.$t('mediaBuying.channels.cancel'),
                    },
                    {
                      text: this.$t('promoCodes.promocodes.delete'),
                      type: 'danger',
                      click: () => deleteKey(row.data.id),
                    },
                  ],
                },
              }, [elTooltip]);
            },
          },
        ].filter(el => !!el),
      }
    },
    rows() {
      return this.$store.state.promocode.promocodes.map((e) => {
        const subids = [];

        for (let i = 1; i <= 5; i++) {
          const key = `subid${i}`;
          subids.push({ value: e[key] });
        }

        const affiliate = this.$store.state.subaffiliates.all.find(i => i.data.id === e.affiliate_id);
        let tail = false;
        while (!tail) {
          const item = subids.pop();

          if (item === undefined) {
            subids.push({ value: null });
            break;
          }

          tail = item.value;

          if (tail) {
            subids.push(item);
          }
        }

        return {
          data: {
            id: e.id,
            affiliateOfferId: e.affiliate_offer_id,
            subids,
            traffic_source_id: e.traffic_source_id,
          },
          promo_code: e.promo_code,
          affiliate_id: affiliate ? affiliate.email : '',
          sub_affiliate_id: e.affiliate_id,
          registrations: e.registrations,
          players: e.players,
          created_at: e.created_at,
        };
      });
    },
    isArchived() {
      return this.statusChannel === 'archived' || this.statusCampaign === 'archived';
    },
  },
  methods: {
    addPromocode(row) {
      this.$refs.addCode.open(row);
    },
    deleteKey(id) {
      this.$store.dispatch('deletePromoCode', id)
        .then(() => this.update());
    },
    handleSort({
      prop, order, sort_by, sort_dir,
    }) {
      const column = prop || sort_by;
      const dir = order || sort_dir;
      this.$emit('update', {
        tab: 'promocodes',
        prop: column,
        order: dir,
      });
    },
    update() {
      this.$emit('update', {
        tab: 'promocodes',
        prop: this.sorting.prop,
        order: this.sorting.order,
        search: this.search,

        limit: this.pageSize,
        offset: (this.page - 1) * this.pageSize,
      });
    },
    async fetchData() {
      this.update();
    },
  },
  created() {
    this.actions.operations.map((e) => {
      if (e.component) {
        e.component = e.component.bind(this);
      }
    });
  },
  mounted() {
    window.copyToClipboard = this.copyToClipboard;
  },
  activated() {
    window.copyToClipboard = this.copyToClipboard;
  },
  deactivated() {
    delete window.copyToClipboard;
  },
  watch: {
    activeTab() {
      if (this.activeTab === 'promocodes') {
        this.update();
      }
    },
    tableSorting: {
      immediate: true,
      deep: true,
      handler(value) {
        this.sorting = {
          prop: value.sort_column,
          order: value.sort_dir,
        };
      },
    },
  },
};
</script>
