import moment from 'moment';
import numeral from 'numeral';
import empty from 'src/assets/img/empty.svg';
import formattedCurrency from 'src/helpers/formattedCurrency.js';

function date(v) {
  const moment = this.$moment(v);
  return moment.isValid()
    ? `<b class="text-green">${moment
      .locale(this.$i18n.locale)
      .format('DD MMM YYYY')}</b>`
    : '-';
}

function dateFull(v) {
  const moment = this.$moment(v);
  return moment.isValid()
    ? `<span class="">${moment
      .locale(this.$i18n.locale)
      .format('DD MMM YYYY HH:mm')}</span>`
    : '-';
}

function dateFulls(v) {
  const moment = this.$moment(v);
  return moment.isValid()
    ? `<span class="">${moment
      .locale(this.$i18n.locale)
      .format('DD MMM YYYY HH:mm:ss')}</span>`
    : '-';
}

function month(v, t) {
  const key = `calendar.month.${v}`;
  return `<b class="text-red">${t(key)}</b>`;
}

function hour(v, t) {
  if (!v) return;
  const key = `calendar.hour.${v}`;
  return `<b class="text-red">${v.toString().length <= 2 ? t(key) : v}</b>`;
}

function minute(v, t) {
  const key = 'calendar.minute';
  const minute = v + 1;
  let count = 'st';
  if (minute === 2) {
    count = 'nd';
  }
  if (minute === 3) count = 'rd';
  if (minute > 3) count = 'th';
  return `<b class="text-red">${minute + count} ${t(key)}</b>`;
}

function number(num) {
  try {
    return numeral(num).format('0,0');
  } catch (e) {
    return num;
  }
}

function site_player_id(v) {
  let text = v;
  if (text === 'Not registered') text = this.$t('customReports.not_registered');
  if (v === 0) text = this.$t('customReports.allPlayers');
  return `<b class="text-green">${text}</b>`;
}

function group(v) {
  let text = v;
  if (!v) text = '-';
  return `<b class="text-green">${text}</b>`;
}

function groupCustom(v) {
  let text = v;
  if (!v) text = this.$t('customReports.totals');
  return `<b class="text-green">${text}</b>`;
}

function percent(value) {
  return `${parseFloat(value).toFixed(2)}%`;
}

function currencyFilter(v) {
  const amount = v;
  let sum = this.$options.filters.currency(amount, '$', null, {
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalSeparator: ',',
  });
  sum = sum.includes('NaN') ? amount : sum;

  if (amount < 0) {
    return `<span class="text-red">${sum}</span>`;
  }

  if (amount > 0) {
    return `<span class="text-green">${sum}</span>`;
  }

  return `<span>${sum}</span>`;
}

function currencyFilterUSD(v) {
  const amount = v;
  let sum = this.$options.filters.currency(amount, 'USD', null, {
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalSeparator: ',',
  });
  sum = sum.includes('NaN') ? amount : sum;

  if (amount < 0) {
    return `<span class="text-red">${sum}</span>`;
  }

  if (amount > 0) {
    return `<span class="text-green">${sum}</span>`;
  }

  return `<span>${sum}</span>`;
}

function currencyColorless(v) {
  const amount = v;
  let sum = this.$options.filters.currency(amount, '$', null, {
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalSeparator: ',',
  });
  sum = sum.includes('NaN') ? amount : sum;

  if (amount < 0) {
    return `<span class="">${sum}</span>`;
  }
  if (amount > 0) {
    return `<span class="">${sum}</span>`;
  }
  return `<span>${sum}</span>`;
}

function channelName(v) {
  return `<span class="channel d-flex justify-content-between">
            <img src='${v.icon || empty}' alt='${v.icon}' />
            <span class="channel-name-bubble name-bubble d-flex justify-content-between">
              <span class="dark-green channel-label ml-6">${v.text}</span>
              ${v.count ? `<span class="tab-count ml-4">${v.count}</span>` : ''}
            </span>
          </span>`;
}

function campaignName(v, _, row) {
  const isRoot = row.id === row.root_campaign_id;
  return `<span class="name-bubble d-flex justify-content-between">
            <span class='dark-green'>${
  v.text ? v.text : v
  // ? (isRoot
  //   ? `${v.text}`
  //   : `${row.root_campaign_name} (${v.text}`)
  // : (isRoot
  // ? `${v}`
  // : `${row.root_campaign_name} (${v}`)
}
            </span>
            ${v.count ? `<span class="tab-count ml-4">${v.count}</span>` : ''}
          </span>`;
}

function channelStatus(v) {
  return `<span class='${v === 'active' ? 'green' : 'red'}'>${this.$t(
    `mediaBuying.channels.status.${v}`,
  )}</span>`;
}

function postStatus(v, _, row) {
  const classes = {
    approved: 'text-green',
    on_review: 'text-orange',
    declined: 'text-red',
    paid: 'text-green',
    processing: 'text-orange',
    not_paid: 'text-red',
    for_rework: 'text-red',
    failed: 'text-red',
  };
  return `<span class='${classes[v]}'>
            ${this.$t(`mediaBuying.posts.status.${v}`)}
            <span class='text-red text-red-alert'>
            ${
  row.needs_attention
    ? '<i class="fas fa-exclamation-circle pl-2"></i>'
    : ''
}
          </span>
          </span>`;
}

function postType(v) {
  return `<span class='${
    v === 'publication' ? 'text-green' : 'text-red'
  }'>${this.$t(`mediaBuying.posts.type.${v}`)}</span>`;
}

function visibility(v) {
  return `<span>${this.$t(`media.${v}`)}</span>`;
}

function platformName(v) {
  if (v.length === 0) return '–';
  const item = (text, icon) => `<span class="channel">
                        <span>
                          <img src='${icon}' alt='${icon}' />
                        </span>
                        <a href='${text}' class="dark-green channel-label ml-4">${text}</a>
                      </span>`;
  return v.reduce((acc, { text, icon }) => `${acc}${item(text, icon)}`, '');
}

function paymentMethod(v) {
  return `<span class='payment'>${
    v.logo ? `<img src="${v.logo}" alt="${v.name}" />` : v.name
  }</span>`;
}

function moderationStatus(v) {
  const classes = {
    approved: 'text-green',
    on_review: 'text-orange',
    declined: 'text-red',
    paid: 'text-green',
    processing: 'text-orange',
    not_paid: 'text-red',
    for_rework: 'text-red',
    failed: 'text-red',
  };
  return (
    v
    && `<span class='${classes[v]}'>
                ${this.$t(`mediaBuying.payments.status.${v}`)}
              </span>`
  );
}

function moderationStatusAlert(v, _, row) {
  const classes = {
    approved: 'text-green',
    on_review: 'text-orange',
    declined: 'text-red',
    paid: 'text-green',
    processing: 'text-orange',
    not_paid: 'text-red',
    for_rework: 'text-red',
    failed: 'text-red',
  };
  return (
    v
    && `<span class='${classes[v]}'>
                ${this.$t(`mediaBuying.payments.status.${v}`)}
                <span class='text-red text-red-alert'>
                ${
    row.needs_attention
      ? '<i class="fas fa-exclamation-circle pl-2"></i>'
      : ''
    }
              </span>
              </span>`
  );
}

function promoCode(v) {
  return `<span>${v} <i class="far fa-copy text-green" onclick="copyToClipboard('${v}')"></i></span>`;
}

function languageCodes(v) {
  return v.length ? v.join(', ') : '—';
}

function brands(v) {
  return v.length ? v.map(e => e.site_name).join(', ') : '—';
}

function mediaType(v) {
  return `<span class="d-flex justify-content-end">
            <span class="">${this.$t(`media.${v.text}`)}</span>
            ${
  v.icons.length
    ? `${v.icons
      .map(e => `<i class="far fa-${e} ml-4 mt-2"></i>`)
      .join('')}`
    : ''
}
          </span>`;
}

// $98.91 ($-31.99)
function balance_sum_usd_total(v, k, row) {
  return `$${formattedCurrency(v)}`;
}
function paid_sum_usd_total(v, k, row) {
  return `USD ${formattedCurrency(v)} (USD ${formattedCurrency(
    row.paid_sum_usd_pending,
  )})`;
}
function spent_sum_usd_total(v, k, row) {
  return `USD ${formattedCurrency(v)} (USD ${formattedCurrency(
    row.spent_sum_usd_pending,
  )})`;
}

function datePeriodPost(v, _, row) {
  return `${moment(row.post_period_start, 'YYYY-MM-DD HH:mm').format(
    'DD MMM YYYY HH:mm',
  )} - ${moment(row.post_period_end, 'YYYY-MM-DD HH:mm').format(
    'DD MMM YYYY HH:mm',
  )}`;
}

function datePeriodPayment(v, _, row) {
  return `${moment(row.payment_period_start, 'YYYY-MM-DD HH:mm').format(
    'DD MMM YYYY HH:mm',
  )} - ${moment(row.payment_period_end, 'YYYY-MM-DD HH:mm').format(
    'DD MMM YYYY HH:mm',
  )}`;
}

const currency = (v, _, row, currency) => {
  return `${currency}${formattedCurrency(v)}`;
}

export default {
  date,
  month,
  hour,
  minute,
  number,
  currencyFilter,
  site_player_id,
  percent,
  start_of_week: date,
  start_of_month: date,
  year: date,
  day_of_week: date,
  day_of_month: date,
  created_at: dateFull,
  reg_date: date,
  timestamp: dateFulls,
  affiliate_offer_name: groupCustom,
  affiliate_offer_settings_name: groupCustom,
  traffic_source_name: groupCustom,
  bet_category_name: groupCustom,
  bet_game_name: groupCustom,
  affiliate_player_promo_code_name: groupCustom,
  city_name: groupCustom,
  country_name: groupCustom,
  player_country_name: groupCustom,
  state_name: groupCustom,
  device_type: groupCustom,
  device_vendor: groupCustom,
  device_model: groupCustom,
  os: groupCustom,
  os_version: groupCustom,
  isp: groupCustom,
  connection_type: groupCustom,
  browser: groupCustom,
  browser_version: groupCustom,
  referrer: groupCustom,
  referrer_domain: groupCustom,
  language: groupCustom,
  referred_email: groupCustom,
  external_id: group,
  subid1: group,
  subid2: group,
  subid3: group,
  subid4: group,
  subid5: group,
  subid6: group,
  subid7: group,
  subid8: group,
  subid9: group,
  subid10: group,
  deposits_first_avg: currencyFilter,
  deposits_first_sum: currencyFilter,
  deposits_other_sum: currencyFilter,
  deposits_all_sum: currencyFilter,
  deposit_bonus_sum: currencyFilter,
  deposits_other_avg: currencyFilter,
  deposits_all_avg: currencyFilter,
  deposits_all_median: currencyFilter,
  deposits_other_median: currencyFilter,
  deposits_withdrawals_sum: currencyFilter,
  bet_new_sum: currencyFilter,
  bet_result_games_sum: currencyFilter,
  bet_result_games_profit_sum: currencyFilter,
  bet_new_sports_sum: currencyFilter,
  bet_result_sports_profit_sum: currencyFilter,
  bet_result_profit_sum: currencyFilter,
  bet_sell_profit_sum: currencyFilter,
  bet_insurance_sum: currencyFilter,
  bet_insurance_result_sum: currencyFilter,
  profit_sum: currencyFilter,
  revenue_sum: currencyFilter,
  bet_result_sum: currencyFilter,
  bet_new_games_sum: currencyFilter,
  bet_result_sports_sum: currencyFilter,
  affiliate_program_profit_no_revenue_sum: currencyFilter,
  bet_insurance_result_no_revenue_sum: currencyFilter,
  bet_insurance_result_revshare_sum: currencyFilter,
  bet_result_games_no_revenue_sum: currencyFilter,
  bet_result_games_profit_no_revenue_sum: currencyFilter,
  bet_result_games_profit_revshare_sum: currencyFilter,
  bet_result_games_revshare_sum: currencyFilter,
  bet_result_no_revenue_sum: currencyFilter,
  bet_result_profit_no_revenue_sum: currencyFilter,
  bet_result_profit_revshare_sum: currencyFilter,
  bet_result_revshare_sum: currencyFilter,
  bet_result_sports_no_revenue_sum: currencyFilter,
  bet_result_sports_profit_no_revenue_sum: currencyFilter,
  bet_result_sports_profit_revshare_sum: currencyFilter,
  bet_result_sports_revshare_sum: currencyFilter,
  bet_sell_profit_no_revenue_sum: currencyFilter,
  bet_sell_profit_revshare_sum: currencyFilter,
  deposits_all_cpa_sum: currencyFilter,
  deposits_all_no_revenue_sum: currencyFilter,
  deposits_all_revshare_sum: currencyFilter,
  deposits_first_cpa_sum: currencyFilter,
  deposits_first_no_revenue_sum: currencyFilter,
  deposits_first_revshare_sum: currencyFilter,
  deposits_flow_sum: currencyFilter,
  deposits_other_cpa_sum: currencyFilter,
  deposits_other_no_revenue_sum: currencyFilter,
  deposits_other_revshare_sum: currencyFilter,
  deposits_withdrawals_no_revenue_sum: currencyFilter,
  deposits_withdrawals_revshare_sum: currencyFilter,
  new_players_affiliate_program_profit_no_revenue_sum: currencyFilter,
  new_players_chargebacks_sum: currencyFilter,
  new_players_profit_no_revenue_sum: currencyFilter,
  new_players_profit_revshare_sum: currencyFilter,
  new_players_revenue_cpa_sum: currencyFilter,
  new_players_revenue_no_revenue_sum: currencyFilter,
  new_players_revenue_revshare_sum: currencyFilter,
  new_players_revenue_sum: currencyFilter,
  profit_no_revenue_sum: currencyFilter,
  profit_revshare_sum: currencyFilter,
  revenue_cpa_sum: currencyFilter,
  revenue_no_revenue_sum: currencyFilter,
  revenue_revshare_sum: currencyFilter,
  deposits_sum_usd: balance_sum_usd_total,
  profit_sum_usd: currencyFilter,
  cost_per_player_usd: balance_sum_usd_total,
  to_hold: currencyColorless,
  to_available: currencyColorless,
  hold_balance: currencyColorless,
  balance: currencyColorless,
  impressions_count: number,
  impressions_uniq_count: number,
  visits_count: number,
  visits_uniq_count: number,
  registrations_count: number,
  deposits_first_count: number,
  deposits_other_count: number,
  deposits_all_count: number,
  deposits_withdrawals_count: number,
  players_with_first_deposit_count: number,
  players_with_deposit_count: number,
  bet_new_count: number,
  bet_new_sports_count: number,
  bet_new_games_count: number,
  bet_result_count: number,
  bet_result_sports_count: number,
  bet_result_games_count: number,
  bet_sell_count: number,
  bet_insurance_count: number,
  bet_insurance_result_count: number,
  players_with_bet_count: number,
  visits_per_impression_percent: percent,
  registrations_per_visit_percent: percent,
  deposits_first_per_visit_percent: percent,
  deposits_first_per_registration_percent: percent,
  re_deposits_percent: percent,
  channel_name: channelName,
  campaign_name: campaignName,
  balance_sum: currencyFilter,
  spent_sum: currencyFilter,
  paid_sum: currencyColorless,
  channel_status: channelStatus,
  post_status: postStatus,
  post_type: postType,
  post_sum: currencyFilter,
  platform_name: platformName,
  moderation_status: moderationStatusAlert,
  payout_status: moderationStatus,
  payment_method: paymentMethod,
  promo_code: promoCode,
  language_codes: languageCodes,
  sizes: languageCodes,
  visible_brands: brands,
  media_type: mediaType,
  visibility,
  balance_sum_usd_total,
  balance_sum_usd_approved: balance_sum_usd_total,
  paid_sum_usd_approved: balance_sum_usd_total,
  paid_sum_usd_pending: balance_sum_usd_total,
  spent_sum_usd_approved: balance_sum_usd_total,
  spent_sum_usd_pending: balance_sum_usd_total,
  paid_sum_usd_total,
  spent_sum_usd_total,
  period_line: datePeriodPost,
  period_line2: datePeriodPayment,
  currency,
  'date-time': dateFull,
};
