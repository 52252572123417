<template>
  <section class="promo__codes">
    <table-card
      ref="datatable"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :actions="actions"
      :page.sync="page"
      :page-size.sync="pageSize"
      :rows="rows"
      :count="$store.state.promocode.totalRows"
      :show-total="false"
      :row-selectable="false"
      dont-fill-empty
      extend-height
      tablet-layout
      i18n-path="reports"
      @fetch-data="fetchDataTable"
    >
      <table-card-search-header
        slot="header"
        class="ui-g-md"
        v-model="search"
        show-actions-mobile
        @search="handleSearch"
      >
        <ui-button
          slot="actions"
          icon="refresh"
          @click="refresh"
        />
      </table-card-search-header>

      <template slot="actionsPrepend">
        <div class="ui-g-md ui-mobile-hide">
          <ui-button
            icon="refresh"
            class="btn btn-lowercase "
            @click="refresh"
          >
            <span class="ml-4">{{ $t('filters.refresh') }}</span>
          </ui-button>
        </div>
        <tooltip
          class="ui-g-md tooltip-btn-disabled"
          :title="$t('tooltip_limits.promoCodes')"
          placement="bottom-left"
          :disabled="!addDisabled"
        >
          <button
            v-if="!isEmptyOffers && iCreatePromoCode"
            class="btn btn-primary btn-sm ui-mobile-12"
            :disabled="addDisabled"
            @click="addCode"
          >
            + {{ $t('promoCodes.promocodes.addPromocode') }}
          </button>
        </tooltip>
      </template>
    </table-card>


    <add-promo-code
      ref="addCode"
      :offset-params="{
        ...queryParams,
        sort_column,
        sort_dir,
        limit: pageSize,
        search: search,
        media_buying_campaign_id: 'null'
      }"
    />

    <edit-promo-code
      ref="editCode"
      :offset-params="{
        ...queryParams,
        sort_column,
        sort_dir,
        limit: pageSize,
        search: search,
        media_buying_campaign_id: 'null'
      }"
    />
  </section>
</template>

<script>
import moment from 'moment';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper.vue';
import TableCard from 'src/components/Cards/TableCard.vue';
import TableCardSearchHeader from 'src/components/Cards/TableCardSearchHeader.vue';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import { setTimeout } from 'timers';
import { pageSizeMixin, resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import Tooltip from 'src/components/Tooltip.vue';
import AddPromoCode from './PromoCodes/AddPromoCode';
import EditPromoCode from './PromoCodes/EditPromoCode';

const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = '/promo-codes';
const pageLimitProp = 'pageSize';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

const defaultParams = {
  totalRows: 0,
  search: '',
  page: 1,
  pageSize,
  sort_column: 'created_at',
  sort_dir: 'desc',
};

const fetch = async (ctx, params) => {
  if (ctx.$_.isEmpty(params)) {
    params = {
      ...defaultParams,
    };
  }

  const trafficSources = () => ctx.$store.dispatch('getTrafficSources', {});
  const promocodeOffers = () => ctx.$store.dispatch('getPromocodeOffers');
  const count = () => ctx.$store.dispatch('getPromocodesCount', { media_buying_campaign_id: 'null' });
  const promocodes = () => ctx.$store.dispatch('getPromocodes', {
    sort_column: params.sort_column || defaultParams.sort_column,
    sort_dir: params.sort_dir || defaultParams.sort_dir,
    search: params.search || '',
    limit: ctx.pageSize || pageSize,
    media_buying_campaign_id: 'null',
    ...params,
  });

  const result = await Promise.all([trafficSources(), promocodeOffers(), count(), promocodes()]);

  return [result[2], result[3]];
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  fetch,
  windowRefreshHook: true,
  initPeriod: 'init',
  sortByName: 'sort_column',
  sortDirName: 'sort_dir',
});


export default {
  fetch,
  components: {
    AddPromoCode,
    EditPromoCode,
    PeriodWrapper,
    Mobtable,
    Mobpagination,
    TableCard,
    TableCardSearchHeader,
    Tooltip,
  },
  mixins: [
    UrlMixin,
    UrlMixinHooks,
    InjectPlugins,
    HelpersMixin,
    pageSizeMixin,
    tableFlowMixin,
  ],
  props: ['fetchedData', 'passedParams'],
  data() {
    return {
      viewName,
      pageLimitProp,
      loading: false,
      columns: [
        {
          name: 'created_at',
          align: 'left',
          sortable: 'custom',
          filterable: false,
          width: 155,
          format: 'date-time',
          source: 'i18n',
          i18nPath: 'reports',
        }, {
          name: 'promo_code',
          align: 'left',
          filterable: false,
          width: 300,
          sortable: false,
        }, {
          name: 'registrations',
          align: 'center',
          filterable: false,
          sortable: false,
        }, {
          name: 'players',
          align: 'center',
          filterable: false,
          sortable: false,
        },
      ],
      lastQuery: {},
      isTableReady: false,
    };
  },
  computed: {
    actions() {
      return {
        width: this.isEmptyOffers ? 80 : 100,
        name: () => this.$t('profile.apiKeys.table.actions'),
        operations: [{
          name: 'statistics',
          component: (row, ctx) => {
            row = _.cloneDeep(row);
            const { $router, compressUrlQuery } = this;
            const urlQuery = {};
            urlQuery.openPromocodeReport = row;
            urlQuery.passedPeriod = {
              from: moment()
                .startOf('month')
                .format('YYYY-MM-DD 00:00:00'),
              to: moment()
                .endOf('day')
                .format('YYYY-MM-DD 23:59:59'),
              period: 30,
            };
            const span = ctx.$createElement('a', {
              on: {
                click(e) {
                  e.cancelBubble = true;
                  $router.push({
                    name: 'routes.custom',
                    params: { urlQuery: compressUrlQuery(urlQuery) },
                  });
                },
              },
              attrs: {
                href: `#/dashboard/custom/${this.compressUrlQuery(urlQuery)}`,
              },
            }, [ctx.$createElement('i', {
              attrs: {
                class: 'far fa-chart-bar text-info',
              },
            })]);
            return ctx.$createElement('el-tooltip', {
              attrs: {
                class: 'el-tooltip icon__wrapper text-info',
                placement: 'top',
                content: this.$t('promoCodes.promocodes.statistics'),
                'open-delay': 500,
              },
            }, [span]);
          },
        },
          !this.isEmptyOffers && {
            name: 'edit',
            component: (row, ctx) => {
              const { editCode } = this;
              const span = ctx.$createElement('span', {
                on: {
                  click(e) {
                    e.cancelBubble = true;
                    editCode({
                      promo_code: row.promo_code,
                      affiliateOfferId: row.data.affiliateOfferId,
                      id: row.data.id,
                      traffic_source_id: row.data.traffic_source_id,
                      subids: row.data.subids,
                    });
                  },
                },
              }, [ctx.$createElement('i', {
                attrs: {
                  class: 'far fa-pen text-green',
                },
              })]);
              return ctx.$createElement('el-tooltip', {
                attrs: {
                  class: 'el-tooltip icon__wrapper text-green',
                  placement: 'top',
                  content: this.$t('postbacks.card.edit'),
                  'open-delay': 500,
                },
              }, [span]);
            },
          }, {
            name: 'delete',
            component: (row, ctx) => {
              const { deleteKey } = this;
              const span = ctx.$createElement('span', {
                on: {
                  click(e) {
                    e.cancelBubble = true;
                  },
                },
              }, [ctx.$createElement('i', {
                attrs: {
                  class: 'far fa-trash-alt text-red',
                },
              })]);
              const elTooltip = ctx.$createElement('el-tooltip', {
                attrs: {
                  class: 'el-tooltip icon__wrapper text-red',
                  placement: 'top',
                  content: this.$t('promoCodes.promocodes.delete'),
                  'open-delay': 500,
                },
              }, [span]);
              return ctx.$createElement('ac-popover', {
                attrs: {
                  width: 250,
                  message: this.$t('mediaBuying.campaigns.delete_promocode_message'),
                  buttons: [{
                    text: this.$t('mediaBuying.channels.cancel'),
                  }, {
                    text: this.$t('promoCodes.promocodes.delete'),
                    type: 'danger',
                    click: () => deleteKey(row.data.id),
                  }],
                },
              }, [elTooltip]);
            },
          }].filter(el => !!el),
      }
    },
    isEmptyOffers() {
      return this.$_.isEmpty(this.$store.state.promocode.offers);
    },
    iCreatePromoCode() {
      return this.$store.state.settings.limitations.allow_promo_code_create_or_edit;
    },

    rows() {
      return this.$store.state.promocode.promocodes.map((e) => {
        const subids = [];
        for (let i = 1; i <= 5; i++) {
          const key = `subid${i}`;
          subids.push({ value: e[key] });
        }

        let tail = false;
        while (!tail) {
          const item = subids.pop();

          if (item === undefined) {
            subids.push({ value: null });
            break;
          }
          tail = item.value;
          if (tail) subids.push(item);
        }

        return {
          data: {
            id: e.id,
            affiliateOfferId: e.affiliate_offer_id,
            subids,
            traffic_source_id: e.traffic_source_id,
          },
          promo_code: e.promo_code,
          registrations: e.registrations,
          players: e.players,
          created_at: e.created_at,
        };
      });
    },
    addDisabled() {
      const { max_promo_codes } = this.$store.state.settings.limitations;
      return max_promo_codes !== null && this.$store.state.promocode.count >= max_promo_codes;
    },
  },
  mounted() {},

  created() {
    this.actions.operations.map((e) => {
      if (e.component) {
        e.component = e.component.bind(this);
      }
    });
    this.setFromQuery(this.passedParams);
  },

  activated() {},

  methods: {
    async getPromoCodes(data = {}) {
      this.loading = true;
      await this.$store.dispatch('getPromocodesCount', { media_buying_campaign_id: 'null' });
      return await this.$store.dispatch('getPromocodes', {
        ...this.queryParams,
        limit: this.pageSize,
        ...this.period,
        ...data,
        ...this.sorting,
        media_buying_campaign_id: 'null',
      })
        .then(() => {
          setTimeout(() => this.loading = false, 800);
        });
    },
    deleteKey(name) {
      this.$store
        .dispatch('deletePromoCode', name)
        .then(() => {
          if (this.rows.length === 1 && this.page > 1) {
            this.page = this.page - 1;
          }

          this.getPromoCodes()
            .then(() => {
              this.$alert.success(this.$t('promoCodes.alerts.promocodeDeleted'));
            });
        })
        .catch(() => {
          this.$alert.error(this.$t('promoCodes.alerts.unexpectedError'));
        });
    },
    addCode() {
      if (!this.addDisabled) {
        this.$refs.addCode.open();
      }
    },
    editCode(promocode) {
      this.$refs.editCode.open(promocode);
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/promocodes/promocodes';

.promo__codes {
  .table-card-search-header__content {
    flex-wrap: nowrap;
  }
  .ui-tooltip__title-host.bottom-left {
    bottom: -4px;
  }
}
</style>
