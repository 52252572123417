<template>
  <section>
    <modal :show.sync="active" :class="{ opacity: hiddenModal }" class="offer media-tags" @close="close">
      <template>
        <h5 slot="header" class="modal-title mt-8">
          {{ $t('mediaTags.genTag') }}
        </h5>
        <div class="row media-tags__row">
          <hr class="mb-6" />
          <div class="col-12 pl-0 pr-0">
            <div class="row pb-0">
              <div class="col-12">
                <!-- offer -->
                <div v-if="hideSelect(offers)" class="select-wrapper w-100 mb-10">
                  <label>{{ $t('mediaTags.offer') }}</label>
                  <v-select :id="`${params_id}promoMaterials.offer`" v-model="offer" :options="offers" class="el-input" @change="selectOffer" />
                </div>
                <!-- brand -->
                <div v-if="active && hideSelect(brands)" class="select-wrapper w-100 mb-10" :class="{ 'disabled__size': offer === '' }">
                  <label>{{ $t('mediaTags.brand') }}</label>
                  <v-select
                    :id="`${params_id}promoMaterials.brands`"
                    v-model="brand"
                    :options="brands"
                    :disabled="offer === ''"
                    class="el-input"
                    @change="changeBrand"
                  />
                </div>

                <!-- rotation -->
                <div v-if="hideSelect(rotations)" class="select-wrapper w-100 mb-10" :class="{ 'disabled__size': brand === '' }">
                  <label>{{ $t('mediaTags.rotation') }}</label>
                  <v-select :id="`${params_id}offers.rotation`" v-model="rotation" :options="rotations" class="el-input" @change="changeRotation" />
                </div>

                <!-- target -->
                <div :class="{ disabled__size: rotation === '' }" class="select-wrapper select-wrapper__target">
                  <label>{{ $t('mediaTags.target') }}</label>
                  <v-select
                    :id="`${params_id}promoMaterials.targetId`"
                    v-model="targetId"
                    :options="urls"
                    :disabled="rotation === ''"
                    class="el-input"
                    @change="selectTarget"
                  />
                </div>
                <div class="db-wrap d-flex justify-content-between">
                  <!-- size -->
                  <div
                    v-if="type === 'html5_banner' || type === 'simple_banner'"
                    :class="{ disabled__size: type === 'landing' || targetId === '' }"
                    class="select-wrapper w-100 mt-12"
                  >
                    <label>{{ $t('mediaTags.size') }}</label>
                    <v-select
                      :id="`${params_id}promoMaterials.size`"
                      v-model="size"
                      :options="sizes || []"
                      :disabled="type === 'landing' || targetId === ''"
                      class="el-input"
                      @change="handlerSizes"
                    />
                  </div>
                  <!-- languages -->
                  <div
                    v-if="($store.state.media.mediacontent.language_codes || []).length > 1"
                    :class="{ 'select-wrap-lang ml-0': type !== 'html5_banner' && type !== 'simple_banner', disabled__language: targetId === '' }"
                    class="select-wrapper w-100 ml-16 mt-12"
                  >
                    <label>{{ $t('mediaTags.languages') }}</label>
                    <v-select
                      :id="`${params_id}promoMaterials.language`"
                      v-show="($store.state.media.mediacontent.language_codes || []).length > 1"
                      v-model="language"
                      :options="languages || []"
                      :disabled="targetId === ''"
                      class="el-input"
                      @change="handlerLanguage"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 pb-0">
                <div class="generate-tag__right-col">
                  <div
                    :class="{ 'disable-input': targetId === '' }"
                    class="form-group select-wrapper generate-tag__form-group generate-tag__form-group--underline mt-14"
                  >
                    <label class="generate-tag__label generate-tag__label--final" for="final-url">{{ $t('mediaTags.url') }}</label>
                    <input
                      id="final-url"
                      v-model="link"
                      :disabled="targetId === ''"
                      autocomplete="off"
                      type="text"
                      class="form-control generate-tag__input mt-0"
                      @input="inputLink"
                    />
                  </div>
                  <!-- tags -->
                  <div class="tag-wrap mb-4">
                    <span
                      v-for="item in links"
                      :key="item.page_url"
                      :class="{ 'el-tag--selected': link === item.page_url, 'disabled-link': targetId === '' }"
                      class="el-tag el-tag--primary el-tag--small"
                      @click="setLink(item.page_url)"
                    >{{ item.name }}</span>
                  </div>
                  <!-- traffic sources -->
                  <el-popover ref="popover" v-model="warningOpen" placement="top-end" trigger="manual" width="320">
                    <p>{{ $t('promoCodes.addPromocodes.changeSubId') }}</p>
                    <button class="btn btn-outline-primary btn-md" @click="saveSubids">
                      {{ $t('offerRequest.btnCancel') }}
                    </button>
                    <button
                      class="btn btn-primary btn-md ml-3"
                      @click="
                        confirmChanges();
                        warningOpen = false;
                      "
                    >
                      {{ $t('offerRequest.btnConfirm') }}
                    </button>
                  </el-popover>
                  <div
                    v-popover:popover
                    v-if="type === 'landing' || 'html5_banner'"
                    :class="{ disabled__size: targetId === '' }"
                    class="select-wrapper mt-14"
                  >
                    <label>{{ $t('trafficSources.trafficSource') }}</label>
                    <v-select
                      :id="`${params_id}promoMaterials.traffic`"
                      v-model="traffic_source_id"
                      :disabled="targetId === ''"
                      :options="trafficSource"
                      class="el-input"
                      @change="
                        checkChange = false;
                        getUrlD();
                      "
                    />
                  </div>
                  <!-- landing subids -->
                  <div class="row row-wrap__subid">
                    <div v-for="(item, i) in form.subids" :key="i" :class="{ 'disable-input': targetId === '' }" class="col-6 col-6__add-subid">
                      <fg-input
                        v-model="item.value"
                        :disabled="targetId === ''"
                        :placeholder="$t('trafficSources.postbacks.notSpecified')"
                        class="no-border form-control-lg mb-0"
                      >
                        <label slot="label" class="d-flex justify-content-between">
                          <span>SubID{{ i + 1 }}</span>
                          <a
                            v-if="calcSubIdInTrafficSource < i + 1"
                            v-show="form.subids.indexOf(item) == form.subids.length - 1 && form.subids.length > 1"
                            href="#"
                            class="btn-cross"
                            @click.prevent="popSubIds"
                          >
                            <span>×</span>
                          </a>
                        </label>
                      </fg-input>
                    </div>
                    <div class="col-6 d-flex align-items-end col-6__add-subid">
                      <button
                        :disabled="form.subids.length >= 5 || targetId === ''"
                        class="btn btn-block btn-outline-primary btn-sm mb-0"
                        @click.prevent="form.subids.length < 5 && addSubId()"
                      >
                        + {{ $t('promoCodes.addPromocodes.addAnotherSubid') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-12 col-12 textarea__track">
                <div class="textarea__wrap-title">
                  <h6 v-if="type === 'landing'" class="textarea__track-title mb-14">
                    {{ $t('mediaTags.redirectUrl') }}
                  </h6>
                  <h6 v-if="type === 'html5_banner'" class="textarea__track-title mb-14">
                    {{ $t('mediaTags.htmlCode') }}
                  </h6>
                </div>
                <div style="position: relative">
                  <copytext :button="button" :success="$t('offer.card.btnDone')" :text.sync="url" :disabled="warningOpen">
                    <a
                      v-if="type === 'landing' && url"
                      :href="url"
                      target="_blank"
                      class="card__btn-arrow btn btn-outline-primary btn-sm mb-4 mt-4"
                      slot="btnRight"
                    >
                      {{ $t('mediaTags.preview') }}
                      <span class="card__triangle" />
                    </a>
                    <a
                      v-if="type == 'html5_banner' && url"
                      class="card__btn-arrow btn btn-outline-primary btn-sm mb-4 mt-4"
                      @click="mediaPreview(url)"
                      slot="btnRight"
                    >
                      {{ $t('mediaTags.preview') }}
                      <span class="card__triangle" />
                    </a>
                  </copytext>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button slot="footer" class="btn btn-outline-primary btn-sm btn-close-modal" @click="close">
          {{ $t('offer.offerRequestSuccess.close') }}
        </button>
      </template>
    </modal>
    <media-preview ref="mediaPreview" @closeBanner="cBanner" @openBanner="oBanner" />
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { Modal } from 'src/components';
import Copytext from 'src/components/Copytext';
import { Select, Option } from 'element-ui';
import VSelectMenu from 'src/components/VSelectMenu.vue';
import { debounce } from 'lodash';

import mediaPreview from './MediaPreview';

export default {
  components: {
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    Copytext,
    mediaPreview,
    VSelectMenu,
  },
  data() {
    return {
      warningOpen: false,
      langNewArr: [],
      hiddenModal: false,
      params_id: '',
      active: false,
      offers: [],
      offer: null,
      links: [],
      form: {
        name: '',
        extid: '',
        promocode: '',
        subids: [{ value: '' }],
      },
      link: '',
      targetId: '',
      traffic: 'Value',
      size: null,
      language: '',
      url: '',
      button: 'Copy Tag',
      traffic_source_id: '',
      oldTSid: '',
      checkChange: false,
      brand: '',
      brands: [
        {
          id: '',
          text: this.$t('profile.generalInfo.notSpecified'),
        },
      ],
      customSubids: [{ value: '' }],
      locale: '',
      rotation: '',
      type: '',
      paramsSubsId: {},
      getUrlD: debounce(this.getUrl, 800),
      site_page: null,
    };
  },
  computed: {
    ...mapState({
      marketingCampaigns: state => state.mediaBuying.marketingCampaigns,
    }),

    params() {
      if (this.type === 'landing') {
        return {
          affiliate_offer_id: this.offer,
          landing_id: this.targetId,
          traffic_source_id: this.traffic_source_id,
          media_buying_campaign_id: undefined,
          marketing_campaign_id: this.rotation,
          site_page: this.site_page,
          language_code: this.language,
          ...this.paramsSubsId,
        };
      }
      return {
        affiliate_offer_id: this.offer,
        landing_id: this.targetId,
        size: this.size,
        marketing_campaign_id: this.rotation,
        media_buying_campaign_id: undefined,
        traffic_source_id: this.traffic_source_id,
        site_page: this.site_page,
        language_code: this.language,
        ...this.paramsSubsId,
      };
    },

    rotations() {
      const rotations = this.$_.cloneDeep(this.marketingCampaigns)
        .sort(
          (a, b) => {
            if (a.type) {
              return a.type.length - b.type.length;
            }
          },
        )
        .map(e => ({
          id: e.id,
          text: e.name,
        }));
      return rotations.concat({
        id: '',
        text: this.$t('profile.generalInfo.notSpecified'),
      });
    },

    trafficSource() {
      const data = this.$store.state.trafficSource.list.map(e => ({
        id: e.data.id,
        text: e.name,
      }));
      return [
        {
          id: '',
          text: this.$t('offerRequest.noTrafficSources'),
        },
      ].concat(data);
    },

    languages() {
      const dictionary = this.$store.state.misc.languages;
      const data = (this.$store.state.media.mediacontent.language_codes || []).map(e => ({
        id: e,
        text: dictionary.find(f => f.code == e.toLowerCase()).name,
      }));
      return [
        {
          id: '',
          text: this.$t('mediaTags.languagesAuto'),
        },
      ].concat(data);
    },

    sizes() {
      return this.$store.state.media.mediacontent.sizes
        ? this.$store.state.media.mediacontent.sizes.map(e => ({
          id: e,
          text: e,
        }))
        : [];
    },

    urls() {
      const urls = this.$store.state.mediaBuying.landings.map(e => ({
        ...e,
        text: e.name,
      }));
      return urls.concat({
        id: '',
        text: this.$t('profile.generalInfo.notSpecified'),
      });
    },

    calcSubIdInTrafficSource() {
      const id = this.traffic_source_id;
      if (!id) return 1;

      const subidArray = Array(5).fill(null).map((el, i) => `subid${i + 1}`);

      const targetTrafficSoutce = this.$store.state.trafficSource.list
        .find(ts => ts.data.id === id);

      let resultCount = 1;
      subidArray.forEach((el, i) => {
        if (targetTrafficSoutce[el] !== null) {
          resultCount = i + 1;
        }
      });

      return resultCount;
    },
  },
  watch: {
    brand: {
      handler(data) {
        if (data) {
          this.$api.getFinalPages(data).then(res => {
            this.links = res.payload.site_final_page_urls;
          })
        } else {
          this.links = [];
        }
      }
    },
    traffic_source_id(value, oldValue) {
      if (this.checkChange) return;
      this.oldTSid = oldValue;

      let oldSource = this.$store.state.trafficSource.list.filter(e => e.data.id === oldValue)[0];
      oldSource = _.pick(oldSource, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);
      const subids = {};

      if (!value) {
        for (let i = 0; i < 5; i++) {
          const key = `subid${i + 1}`;
          subids[key] = this.form.subids[i] ? this.form.subids[i].value : null;
        }
        if (!_.isEmpty(oldSource) && !_.isEqual(subids, oldSource)) {
          this.addSubids(subids);
          return;
        }

        if (this.customSubids.some(e => e.value)) {
          this.form.subids = this.customSubids;
        } else {
          // if no traffic source selected clear subids
          this.form.subids = [{ value: null }];
        }
        return;
      }

      let source = this.$store.state.trafficSource.list.filter(e => e.data.id === value)[0];
      this.form.extid = source.data.extid || '';
      source = _.pick(source, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);
      // if no traffic source selected, like from start
      if (!oldValue) {
        if (this.form.subids === 1 || !this.form.subids[0].value) {
          // if subids empty
          this.addSubids(source);
        } else {
          // take current subids
          const subids = {};
          for (let i = 0; i < 5; i++) {
            const key = `subid${i + 1}`;
            subids[key] = this.form.subids[i] ? this.form.subids[i].value : null;
          }
          // test if current subids is equal with new subids
          if (_.isEqual(subids, source)) {
            this.form.subids = [{ value: null }];
            this.addSubids(source);
          } else {
            // show warning
            this.warningOpen = true;
          }
        }
        return;
      }

      // take current subids, if traffic source selected
      for (let i = 0; i < 5; i++) {
        const key = `subid${i + 1}`;
        subids[key] = this.form.subids[i] ? this.form.subids[i].value : null;
      }
      // test if current subids is equal with old subids
      if (_.isEqual(subids, oldSource)) {
        this.form.subids = [{ value: null }];
        this.addSubids(source);
      } else {
        // show warning
        this.warningOpen = true;
      }
    },

    locale() {
      this.brands[0].text = this.$t('profile.generalInfo.notSpecified');
    },

    'form.subids': {
      handler(subids) {
        this.parseSubids(subids);
      },
      deep: true,
    },
  },
  activated() {
    this.$store.dispatch('getLanguagesLazy');
    this.getMarketingMedias();
  },
  updated() {
    // здесь возникает ошибка при разлогиникании - '_t'
    this.type == 'landing' ? (this.button = this.$t('offer.card.btn')) : (this.button = this.$t('offer.card.btnCode'));
    this.locale = this.$locale;
  },

  methods: {
    hideSelect(options = []) {
      return options.length > 2;
    },

    setSize() {
      if (this.type === 'html5_banner' || this.type === 'simple_banner') {
        this.size = this.sizes[0].id;
      }
    },

    handlerLanguage() {
      this.getUrl();
    },

    handlerSizes() {
      this.getUrl();
    },

    inputLink() {
      this.site_page = this.link;
      this.getUrlD();
    },

    setLink(url) {
      this.link = url;
      this.site_page = url;
      this.getUrl();
    },

    parseSubids(subids) {
      if (this.targetId !== '') {
        const objSubs = {};
        for (let i = 0; i < subids.length; i++) {
          const key = `subid${i + 1}`;
          objSubs[key] = subids[i] ? subids[i].value : null;
        }

        this.paramsSubsId = objSubs;
        this.getUrlD();
      }
    },

    async changeRotation(v) {
      if (v === '') {
        this.resetForTarget();
        this.targetId = '';
        return;
      }
      if (this.targetId) {
        await this.getUrl();
      }
    },

    selectOffer(v) {
      this.resetForTarget();
      this.rotation = '';
      if (v === '') {
        this.brand = '';
        return;
      }
      // reseting form
      this.brand = '';

      const brandsPm = this.$store.state.media.mediacontent.visible_brands.map(i => i.site_id);
      const brands = this.offers
        .find(e => e.id === this.offer)
        .visible_brands.filter(e => brandsPm.includes(e.site_id))
        .map(e => ({
          id: e.site_id,
          text: e.site_name,
        }));

      this.brands = [
        {
          id: '',
          text: this.$t('profile.generalInfo.notSpecified'),
        },
      ].concat(brands);

      if (brands.length === 1) {
        this.brand = brands[0].id;
        this.changeBrand();
      }
    },

    async changeBrand() {
      this.resetForTarget();
      if (this.brand === '') {
        this.rotation = '';
        return;
      }

      await this.$store.dispatch('getLandings', { site_id: this.brand });
      this.rotation = this.rotations[0].id;

      if (this.urls.length === 2) {
        this.targetId = this.urls[0].id;
        this.selectTarget();
      }
    },

    selectTarget() {
      if (this.targetId === '') {
        this.resetForTarget();
        return;
      }

      this.getUrl();
    },

    async getUrl() {
      if (this.type === 'landing') {
        const {
          payload: { url },
        } = await this.$api.getMarketingMaterialLandingUrl(this.params_id, this.params);
        this.url = url;
        return;
      }

      const {
        payload: { script },
      } = await this.$api.getMarketingMaterialBannerScript(this.params_id, this.params);
      this.url = script;
    },

    getMarketingMedias(visible_brands = [], sub_affiliate_id) {
      return this.$api
        .getAffiliateOffers({
          sub_affiliate_id
        })
        .then((res) => {
          const brandsPm = visible_brands.map(i => i.site_id);
          Promise.all(res.payload.map(offer => this.$api.getAffiliateOffer(offer.id, { sub_affiliate_id }).then(
            res => ({
              name: offer.name,
              text: offer.name,
              id: offer.id,
              visible_brands: res.payload.visible_brands,
            }),
          ))).then((res) => {
            this.offers = [
              {
                id: '',
                text: this.$t('promoCodes.addPromocodes.selectOffer'),
              },
              ...res.filter(e => e.visible_brands.filter(i => brandsPm.includes(i.site_id)).length),
            ];
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },

    async open({ id, type, visible_brands, sub_affiliate_id }) {
      // эти же бренды приходят и в запросе getMediaContent в стор
      this.reset();
      this.type = type;
      this.params_id = id;
      await this.getMarketingMedias(visible_brands, sub_affiliate_id);
      await this.$store.dispatch('getTrafficSources', {
        sub_affiliate_id
      });
      await this.$store.dispatch('getMediaContent', this.params_id);
      this.active = true;
      this.setSize();
      this.countryFilter();
      if (this.offers.length === 2) {
        this.offer = this.offers.filter(f => f.id)[0].id;
        this.selectOffer(this.offer);
      }
    },

    close() {
      this.reset();
      this.active = false;
    },

    reset() {
      this.traffic_source_id = null;
      this.form.extid = '';
      this.offer = '';
      this.brand = '';
      this.rotation = '';
      this.link = '';
      this.links = [];
      this.site_page = '';
      this.targetId = '';
      this.size = '';
      this.form.subids = [{ value: null }];
      this.customSubids = [{ value: null }];
      this.strLang = '';
      this.url = '';
      this.language = '';
      this.paramsSubsId = {};
      this.checkChange = false;
      this.warningOpen = false;
    },
    resetForTarget() {
      this.traffic_source_id = null;
      this.form.extid = '';
      this.link = '';
      this.targetId = '';
      this.form.subids = [{ value: null }];
      this.customSubids = [{ value: null }];
      this.paramsSubsId = {};
      this.site_page = '';
      this.strLang = '';
      this.url = '';
      this.language = '';
      this.checkChange = false;
      this.warningOpen = false;
    },

    mediaPreview(item) {
      this.$refs.mediaPreview.open(item);
    },
    cBanner(v) {
      this.hiddenModal = false;
    },

    oBanner(v) {
      this.hiddenModal = v;
    },

    addSubId() {
      this.form.subids.push({
        value: '',
      });
    },
    popSubIds(item) {
      this.form.subids.pop();
    },

    addSubids(source) {
      if (!source) {
        this.form.subids = [{ value: null }];
        return;
      }
      this.form.subids = [];
      // Push subids
      Object.keys(source).map((k) => {
        if (k.startsWith('subid')) {
          const subid_index = parseInt(k.replace('subid', '')) - 1;
          if (source[k]) {
            this.form.subids.push({
              value: source[k],
            });
          } else {
            this.form.subids.push({
              value: null,
            });
          }
        }
      });
      // reduce subids
      for (let i = this.form.subids.length - 1; i >= 1; i--) {
        if (!this.form.subids[i].value) this.form.subids.pop();
        else break;
      }
    },

    confirmChanges() {
      this.form.subids = [{ value: null }];
      this.customSubids = [{ value: null }];
      const source = this.$store.state.trafficSource.list.filter(e => e.data.id === this.traffic_source_id)[0];
      this.addSubids(source);
    },

    saveSubids() {
      if (this.form.subids.length < this.calcSubIdInTrafficSource) {
        const remainder = this.calcSubIdInTrafficSource - this.form.subids.length;

        this.customSubids = [
          ..._.cloneDeep(this.form.subids),
          ...Array(remainder).fill({ value: '' }),
        ].map(el => (!el.value ? { value: '' } : el));
        this.form.subids = this.customSubids;
      } else {
        this.customSubids = _.cloneDeep(this.form.subids);
      }

      this.checkChange = true;
      this.warningOpen = false;
    },

    countryFilter() {
      for (let i = 0; i < this.$store.state.media.mediacontent.language_codes.length; i++) {
        for (let j = 0; j < this.$store.state.misc.languages.length; j++) {
          if (this.$store.state.media.mediacontent.language_codes[i].toLowerCase() === this.$store.state.misc.languages[j].code) {
            this.langNewArr.push(this.$store.state.misc.languages[j]);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/media/mediatags';
</style>
