<template>
  <section class="traffic-source">
    <table-card
      ref="datatable"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :page.sync="page"
      :page-size.sync="pageSize"
      :rows="rows"
      :count="$store.state.trafficSource.totalRows"
      :formaters="formaters"
      :actions="actions"
      :header="$t('trafficSources.title')"
      dont-fill-empty
      extend-height
      i18n-path="reports"
      @fetch-data="fetchDataTable"
    >
      <div slot="actionsPrepend" class="ui-mobile-12 ui-g-md">
        <tooltip
          class="tooltip-btn-disabled"
          :title="$t('tooltip_limits.trafficSources')"
          placement="bottom-left"
          :disabled="!addDisabled"
        >
          <button
            class="btn btn-primary btn-sm ui-mobile-12"
            :disabled="addDisabled"
            @click="addNewTrafficSource"
          >
            + {{ $t('trafficSources.newTrafficSource') }}
          </button>
        </tooltip>
      </div>
    </table-card>

    <add-traffic-source
      ref="addNewTrafficSource"
      :offset-params="queryParams"
    />
    <edit-traffic-source
      ref="editTrafficSource"
      :offset-params="queryParams"
    />
  </section>
</template>

<script>
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import SortBy from 'src/components/SortBy.vue';
import Formaters from 'src/views/Dashboard/CustomReports/formaters';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import {
  pageSizeMixin,
  resolvePageSize,
} from 'src/views/Dashboard/Reports/page_size.js';
import TableCard from 'src/components/Cards/TableCard.vue';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import Tooltip from 'src/components/Tooltip.vue';
import EditTrafficSource from './TrafficSources/EditTrafficSource';
import AddTrafficSource from './TrafficSources/AddTrafficSource';

const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = '/traffic-source';
const pageLimitProp = 'pageSize';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

const defaultParams = {
  page: 1,
  pageSize,
  sort_column: 'created_at',
  sort_dir: 'desc',
};

const fetch = (ctx, params) => {
  if (ctx.$_.isEmpty(params)) {
    params = {
      ...defaultParams,
    };
  }

  const count = () => ctx.$store.dispatch('getTrafficSourceCount');
  const promocodeOffers = () => ctx.$store.dispatch('getPromocodeOffers');
  const trafficSources = () => ctx.$store.dispatch('getTrafficSources', {
    offset: params.offset || 0,
    sort_dir: 'desc',
    sort_column: params.sort_column || 'created_at',
    ...params,
    limit: ctx.pageSize || pageSize,
  });

  return Promise.all([count(), trafficSources(), promocodeOffers()]);
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  fetch,
  windowRefreshHook: true,
  sortByName: 'sort_column',
  sortDirName: 'sort_dir',
});

export default {
  mixins: [
    UrlMixin,
    UrlMixinHooks,
    InjectPlugins,
    pageSizeMixin,
    HelpersMixin,
    tableFlowMixin,
  ],
  fetch,
  components: {
    AddTrafficSource,
    EditTrafficSource,
    Mobtable,
    Mobpagination,
    SortBy,
    TableCard,
    Tooltip,
  },
  props: ['fetchedData', 'passedParams'],
  data() {
    return {
      viewName,
      pageLimitProp,
      pagination: 'true',
      columns: [
        {
          name: 'created_at',
          align: 'left',
          sortable: 'custom',
          filterable: false,
          width: 155,
          format: 'date-time',
        },
        {
          name: 'name_ts',
          align: 'left',
          sortable: false,
          filterable: false,
        },
        {
          name: 'postbacks',
          align: 'center',
          sortable: false,
          filterable: false,
          width: 100,
        },
      ],
      listener: '',
      lastQuery: {},
      formaters: Formaters,
      isTableReady: false,
    };
  },
  computed: {
    isEmptyOffers() {
      return this.$_.isEmpty(this.$store.state.promocode.offers);
    },

    actions() {
      const { editTrafficSource, $router, compressUrlQuery, deleteSource } = this;
      return {
        width: 130,
        name: () => this.$t('profile.apiKeys.table.actions'),
        operations: [
          {
            name: 'tagManager',
            component: (row, ctx) => {
              const span = ctx.$createElement(
                'a',
                {
                  on: {
                    click(e) {
                      e.cancelBubble = true;
                      localStorage.setItem(
                        'getBackUrlTrafficSource',
                        JSON.stringify($router.currentRoute.path),
                      );
                      $router.push(
                        `/dashboard/traffic-source/tag-manager/${
                          row.data.id
                        }/${compressUrlQuery(row.data.id)}`,
                      );
                    },
                  },
                  attrs: {
                    href: `#/dashboard/traffic-source/tag-manager/${
                      row.data.id
                    }/${compressUrlQuery(row.data.id)}`,
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-tag text-info',
                    },
                  }),
                ],
              );
              return ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-info',
                    placement: 'top',
                    content: this.$t('tagManager.card.tagManager'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
            },
          },
          {
            name: 'postbacks',
            component: (row, ctx) => {
              const span = ctx.$createElement(
                'a',
                {
                  on: {
                    click(e) {
                      e.cancelBubble = true;
                      localStorage.setItem(
                        'getBackUrlTrafficSource',
                        JSON.stringify($router.currentRoute.path),
                      );
                      $router.push(
                        `/dashboard/traffic-source/${
                          row.data.id
                        }/${compressUrlQuery(row.data.id)}`,
                      );
                    },
                  },
                  attrs: {
                    href: `#/dashboard/traffic-source/${
                      row.data.id
                    }/${compressUrlQuery(row.data.id)}`,
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-link text-info',
                    },
                  }),
                ],
              );
              return ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-info',
                    placement: 'top',
                    content: this.$t('postbacks.mainTitle'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
            },
          },
          {
            name: 'edit',
            component: (row, ctx) => {
              const {
                data: { id },
              } = row;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click(e) {
                      e.cancelBubble = true;
                      editTrafficSource(id);
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-pen text-green',
                    },
                  }),
                ],
              );
              return ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-green',
                    placement: 'top',
                    content: this.$t('postbacks.card.edit'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
            },
          },
          {
            name: 'delete',
            component: (row, ctx) => {
              const {
                data: { id },
              } = row;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click(e) {
                      e.cancelBubble = true;
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-trash-alt text-red',
                    },
                  }),
                ],
              );
              const elTooltip = ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-red',
                    placement: 'top',
                    content: this.$t('postbacks.card.delete'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
              return ctx.$createElement(
                'ac-popover',
                {
                  attrs: {
                    width: 250,
                    message: this.$t('trafficSources.warning.warningText'),
                    buttons: [
                      {
                        text: this.$t('mediaBuying.channels.cancel'),
                      },
                      {
                        text: this.$t('postbacks.card.delete'),
                        type: 'danger',
                        click: () => deleteSource(id),
                      },
                    ],
                  },
                },
                [elTooltip],
              );
            },
          },
        ].filter((e) => {
          return !(e.name === 'postbacks' && this.isEmptyOffers);
        }),
      };
    },

    rows() {
      return this.$store.state.trafficSource.list.map(e => ({
        data: e.data,
        created_at: e.created_at,
        name_ts: e.name_ts,
        postbacks: this.formatType(e.postbacks),
      }));
    },
    addDisabled() {
      const { max_traffic_sources } = this.$store.state.settings.limitations;

      return max_traffic_sources !== null && this.$store.state.trafficSource.totalRows >= max_traffic_sources;
    },
  },
  methods: {
    formatType(type) {
      return isNaN(type) ? this.$t('paymentHistory.no') : type;
    },
    deleteSource(id) {
      this.$store.dispatch('deleteTrafficSource', id).then(() => {
        if (this.rows.length === 1 && this.page > 1) {
          this.page = this.page - 1;
        }

        this.$store.dispatch('getTrafficSourcesLazy', this.queryParams);
        this.$alert.success(this.$t('trafficSources.postbacks.trafficDeleted'));
      });
    },
    addNewTrafficSource(id) {
      if (!this.addDisabled) {
        this.$refs.addNewTrafficSource.open(id);
      }
    },
    editTrafficSource(id) {
      this.$refs.editTrafficSource.open(id);
    },
    clearTooltip() {
      document.addEventListener(
        'mouseup',
        (this.listener = () => {
          setTimeout(() => {
            const tooltip = document.querySelectorAll('.el-tooltip__popper');
            tooltip.forEach((e, i) => {
              tooltip[i].style.display = 'none';
            });
          }, 500);
        }),
      );
    },
  },
  created() {
    this.setFromQuery(this.passedParams);
    this.actions.operations.forEach(
      o => o.component && (o.component = o.component.bind(this)),
    );
  },
  beforeMount() {
    this.pageSize = this.limit;
  },
  mounted() {
    this.clearTooltip();
  },
  activated() {
    this.clearTooltip();
  },
};
</script>

<style lang="scss">
.traffic-source {
  min-height: calc(100vh - 100px);

  > .card {
    min-height: calc(100vh - 100px);
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
  }

  .head-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 650px) {
    > .card {
      padding: 0 !important;
    }

    .mob-wrap {
      .castom-margin-bottom:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
      }

      .actions a:last-child,
      .btn.text-green {
        border-left: 1px solid #e7ecf1;
      }
    }

    .head-wrap {
      padding: 6px 16px 16px;
      flex-direction: column;
      align-items: baseline;

      .btn-primary {
        margin: 0 !important;
        width: 100%;
      }

      > .d-flex {
        display: block !important;
        width: 100%;
        margin-top: 10px;
      }

      .the-pagination {
        margin-top: 10px;
      }
    }
  }
}
</style>
