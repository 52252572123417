export function getMarketingMaterials({
  search, language_code, site_id, type, visibility, limit, offset, sort_column, sort_dir, sub_affiliate_id,
} = {}) {
  return this.affiliates
    .get('marketing-materials', {
      params: {
        search,
        language_code,
        site_id,
        type,
        visibility,
        limit,
        offset,
        sort_column,
        sort_dir,
        sub_affiliate_id,
      },
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getMarketingMaterial(id) {
  return this.affiliates
    .get(`marketing-materials/${id}`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getMarketingMaterialTag({
  id, affiliate_offer_id, landing_id, traffic_source_id, media_buying_campaign_id,
}) {
  return this.affiliates
    .get(`marketing-materials/${id}/tag`, {
      params: {
        affiliate_offer_id,
        landing_id,
        traffic_source_id,
        media_buying_campaign_id,
      },
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getMarketingMaterialLandingUrl(id, params) {
  return this.affiliates
    .get(`marketing-materials/${id}/landing-url`, { params })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getMarketingMaterialBannerScript(id, params) {
  return this.affiliates
    .get(`marketing-materials/${id}/banner-script`, { params })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getSites() {
  return this.affiliates
    .get('sites')
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}
