<template>
  <section
    class="public-table media-buying-ad"
    :class="{
      wavebg: loading,
    }"
  >
    <table-card
      ref="datatable"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :rows="rows"
      :page.sync="page"
      :page-size.sync="pageSize"
      :count="$store.state.mediaBuying.paymentsCount"
      :formaters="formaters"
      :actions="actions"
      :header="$t('mediaBuying.campaigns.payments')"
      is-static
      dont-fill-empty
      custom-context
      :disable-actions="isArchived"
      placementTooltip="bottom"
      i18n-path="mediaBuying.payments"
      @fetch-data="fetchDataTable"
    >
      <div
        slot="header"
        :class="[
          'ui-d-flex',
          'ui-desktop-no-wrap',
          'ui-mobile-wrap',
          'ui-mobile-12',
        ]"
      >
        <table-card-search-header
          v-model="search"
          class="ui-g-md"
          @search="handleSearch"
        />

        <period-wrapper
          class="ui-g-md ui-mobile-12"
          :settings="period"
          default-all-time
          @update="period = $event"
          @change="changePeriod"
        />

        <ui-filters
          :use-filters="availableFilters"
          :filters="filters"
          :route="route"
          @input="inputFilter"
          @reset="reset"
        />
        <div
          class="checkbox payment-history__checkbox ui-mobile-12 ui-g-md"
        >
          <input
            v-model="actionRequired"
            id="checkbox1"
            type="checkbox"
            @change="fetchData"
          >
          <label
            class="checkbox-label checkbox-label__action-required"
            for="checkbox1"
          >
            {{ $t('mediaBuying.campaign.action_required') }}
          </label>
        </div>
      </div>
    </table-card>

    <request-payment ref="requestPayment" id="PaymentsTable" @addPayment="update" />
    <modify-payment ref="modifyPayment" />
  </section>
</template>

<script>
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import { pageSizeMixin } from 'src/views/Dashboard/Reports/page_size.js';
import RequestPayment from 'src/views/Dashboard/MediaBuying/Modal/RequestPayment';
import ModifyPayment from 'src/views/Dashboard/MediaBuying/Modal/ModifyPayment';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Formatters from 'src/views/Dashboard/CustomReports/formaters';
import SortBy from 'src/components/SortBy';
import eventBus from 'src/lib/eventBus';
import TableCard from 'src/components/Cards/TableCard.vue';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import TableCardSearchHeader from 'src/components/Cards/TableCardSearchHeader.vue';
import UiFilters from 'src/views/Dashboard/MediaBuying/UiFilters/UiFilters';
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper.vue';
import moment from 'moment';
import formatCurrency from '@/mixins/format-currency';

const fieldMap = {
  payment_sum: 'amount_usd',
};

const classes = {
  approved: 'text-green',
  on_review: 'text-orange',
  declined: 'text-red',
  paid: 'text-green',
  processing: 'text-orange',
  not_paid: 'text-red',
  for_rework: 'text-red',
  failed: 'text-red',
};

const defaultParams = {
  page: 1,
  pageSize: 20,
  search: '',
  prop: 'created_at',
  order: 'desc',
  status: '',


  moderationStatus: JSON.parse(localStorage.getItem('/dashboard/media-buying-payments'))?.moderationStatus || '',
  payoutStatus: JSON.parse(localStorage.getItem('/dashboard/media-buying-payments'))?.payoutStatus || '',

  created_at_from: JSON.parse(localStorage.getItem('/dashboard/media-buying-payments'))?.created_at_from
    || '',
  created_at_to: JSON.parse(localStorage.getItem('/dashboard/media-buying-payments'))?.created_at_to
    || '',
  period: JSON.parse(localStorage.getItem('/dashboard/media-buying-payments'))?.period || 2,

};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  offset: 0,
  limit: defaultParams.pageSize,
  params: Object.keys(defaultParams),
  sortByName: 'prop',
  sortDirName: 'order',
  fetchOnActivated: false,
});

export default {
  name: 'PaymentsTableView',
  components: {
    SortBy,
    Mobtable,
    RequestPayment,
    ModifyPayment,
    TableCard,
    TableCardSearchHeader,
    UiFilters,
    PeriodWrapper,
  },

  mixins: [UrlMixin, UrlMixinHooks, InjectPlugins, pageSizeMixin, tableFlowMixin, formatCurrency],
  props: ['statusChannel', 'statusCampaign', 'activeTab', 'tableSorting'],

  data() {
    return {
      route: 'promoMaterial',
      period: { from: '', to: '', period: 2 },
      filters: this.getDefaultFilter(),
      availableFilters: ['moderationStatus', 'payoutStatus'],

      moderationStatus: '',
      payoutStatus: '',

      statusFiltersSettings: {
        minimumResultsForSearch: Infinity,
      },
      activePopperCount: null,
      actionRequired: true,

      columns: [
        {
          name: 'incrementing_id',
          align: 'left',
          filterable: false,
          sortable: 'custom',
          width: 60,
        },
        {
          name: 'campaign_name',
          align: 'left',
          filterable: true,
          minWidth: 140,
          // customTooltip: true,
          linkAppend: (item) => {
            const isRoot = item.campaign_id === item.root_campaign_id;
            return this.$createElement('span',
              {
                class: 'name-bubble ffff d-flex justify-content-between',
                on: {
                // click: this.handleCampaignPush('', '', item),
                  click: () => { this.handleCampaignPush('', '', item); },
                },
              },
              [
                this.$createElement('span', {
                  class: 'dark-green',
                }, isRoot ? `${item.campaign_name}` : `${item.root_campaign_name} (${item.campaign_name})`),
              ]);
          },
        },
        {
          name: 'moderation_status',
          align: 'center',
          filterable: false,
          // sortable: 'custom',
          width: 150,
          class: 'call-alert',
          renderValue: (v, row) => v
            && `<span class='${classes[v]} wrap-red-alert' >
              ${this.$t(`mediaBuying.payments.status.${v}`)}
              <span class='text-red text-red-alert'>
                ${row.needs_attention
    ? '<i class="fas fa-exclamation-circle pl-2"></i>'
    : ''
}
              </span>
            </span>`,
        },
        {
          name: 'payout_status',
          align: 'center',
          filterable: false,
          // sortable: 'custom',
          width: 140,
          renderValue: v => v
            && `<span class='${classes[v]}'>
              ${this.$t(`mediaBuying.payments.status.${v}`)}
            </span>`,
        },
        {
          name: 'payment_sum',
          align: 'right',
          filterable: false,
          sortable: 'custom',
          width: 120,
        },
        {
          name: 'period_line2',
          align: 'left',
          filterable: false,
          width: 258,
          sortable: false,
          renderValue: (v, row) => `${moment(new Date(row.payment_period_start), 'YYYY-MM-DD HH:mm').format(
            'DD MMM YYYY HH:mm',
          )} - ${moment(new Date(row.payment_period_end), 'YYYY-MM-DD HH:mm').format(
            'DD MMM YYYY HH:mm',
          )}`,
        },
        {
          name: 'payment_method',
          align: 'center',
          filterable: false,
          width: 165,
          renderValue: v => `<span class='payment'>${v.logo ? `<img src="${v.logo}" alt="${v.name}" />` : v.name}</span>`,
        },
        {
          name: 'platform_name',
          align: 'left',
          filterable: false,
          width: 165,
          // sortable: 'custom',
          customTooltip: false,
          linkAppend: (item) => {
            if (item.platform_name.length > 1) {
              const spliceAddresses = this.$_.cloneDeep(item.platform_name);
              spliceAddresses.splice(0, 1);
              const addresses = spliceAddresses
                .map(platform => this.$createElement(
                  'div',
                  { class: 'el-dropdown-menu__item' },
                  [
                    this.$createElement(
                      'a',
                      {
                        attrs: {
                          target: '_blank',
                          href: platform.text,
                        },
                        class: 'dd-link-platform_name',
                      },
                      platform.text,
                    ),
                  ],
                ));
              const icon = this.$createElement(
                'img',
                {
                  attrs: {
                    src: item.platform_name[0].icon,
                    alt: item.platform_name[0].icon,
                  },
                  class: 'icon-platform',
                },
              );
              const ip = this.$createElement(
                'a',
                {
                  attrs: {
                    target: '_blank',
                    href: item.platform_name[0].text,
                  },
                  class: 'server-adresses-value',
                },
                item.platform_name[0].text,
              );
              const dropdown = this.$createElement(
                'el-dropdown',
                {
                  class: 'server-adresses',
                  props: {
                    trigger: 'click',
                    placement: 'bottom',
                  },
                  on: {
                    'visible-change': isOpened => (this.activePopperCount = isOpened ? item.id : null),
                  },
                },
                [
                  this.$createElement(
                    'div',
                    {
                      class: 'el-dropdown-link',
                    },
                    [
                      this.$createElement(
                        'span',
                        {
                          class: {
                            'server-adresses-count': true,
                            active: this.activePopperCount === item.id,
                          },
                        },
                        `+${spliceAddresses.length}`,
                      ),
                    ],
                  ),
                  this.$createElement(
                    'el-dropdown-menu',
                    {
                      class: 'server-adresses-dropdown ddgroup',
                    },
                    addresses,
                  ),
                ],
              );
              return this.$createElement('span',
                { class: 'server-adresses-value-wrap' },
                [
                  this.$createElement(
                    'span',
                    { class: 'server-adresses-value_left' },
                    [icon, ip],
                  ),
                  [dropdown],
                ]);
            }
            return item.platform_name.length === 1 && item.platform_name[0].text !== ''
              ? this.$createElement('span', [
                this.$createElement(
                  'img',
                  {
                    attrs: {
                      src: item.platform_name[0].icon,
                      alt: item.platform_name[0].icon,
                    },
                    class: 'icon-platform',
                  },
                ),

                this.$createElement(
                  'a',
                  {
                    attrs: { target: '_blank', href: item.platform_name[0].text },
                    class: 'server-adresses-value',
                  },
                  item.platform_name[0].text,
                )])
              : this.$createElement(
                'span',
                {},
                '–',
              );
          },
        },
        {
          name: 'created_at',
          align: 'left',
          filterable: false,
          sortable: 'custom',
          format: 'date-time',
          width: 150,
        },
        {
          name: 'updated_at',
          align: 'left',
          filterable: false,
          sortable: 'custom',
          format: 'date-time',
          width: 150,
        },
      ],
      actions: {
        fixed: 'right',
        width: 80,
        name: () => this.$t('profile.apiKeys.table.actions'),
        operations: [
          {
            name: 'watched',
            component: (row, ctx) => {
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click: () => {
                      this.markAction(row, row.needs_attention);
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: `fa-regular ${row.needs_attention ? 'fa-circle-exclamation-check' : 'fa-exclamation-circle'}`,
                    },
                  }),
                ],
              );
              return ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: `el-tooltip icon__wrapper ${row.needs_attention ? 'text-green' : 'text-red'} `,
                    placement: 'top',
                    content: row.needs_attention ? this.$t('tickets.header.markAsRead') : this.$t('tickets.header.markAsUnread'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
            },
          },
          {
            name: 'show',
            component: (row, ctx) => {
              const { modifyPayment } = this;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click: async () => {
                      await ctx.$store.dispatch('getPayment', row.id);
                      modifyPayment({ ...ctx.$store.state.mediaBuying.payment });
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-eye',
                    },
                  }),
                ],
              );
              return ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-green',
                    placement: 'top',
                    content: this.$t('mediaBuying.posts.show'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
            },
          },
          {
            name: 'edit',
            component: (row, ctx) => {
              const { requestPayment } = this;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click: async () => {
                      await ctx.$store.dispatch('getPayment', row.id);
                      requestPayment({ ...ctx.$store.state.mediaBuying.payment });
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-pen',
                    },
                  }),
                ],
              );
              return ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: `el-tooltip icon__wrapper text-green ${
                      row.moderation_status !== 'for_rework' && row.payout_status !== 'for_rework' ? 'disabled' : ''
                    }`,
                    placement: 'top',
                    content: this.$t('mediaBuying.posts.edit'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
            },
          },
        ],
      },
      formaters: Formatters,
    };
  },

  watch: {
    filters: {
      immediate: true,
      handler(value) {
        let _value = value;
        if (!value) {
          this.filter = this.getDefaultFilter();
          _value = this.filter;
        }

        this.moderationStatus = _value.moderationStatus;
        this.payoutStatus = _value.payoutStatus;
      },
    },

    activeTab() {
      if (this.activeTab === 'payments') {
        this.update();
        this.$store.dispatch('getPaymentMethod', {});
      }
    },

  },

  computed: {
    iconPlatform() {
      return this.$store.state.misc.postPlatforms;
    },
    rows() {
      return this.$store.state.mediaBuying.payments.map(p => ({
        ...p,
        payment_method: {
          name: p.payment_method_name,
          logo: p.payment_method_logo_base64,
          wallet: p.payment_wallet,
        },

        moderation_status: p.moderation_status,
        payout_status: p.payout_status,
        period: `${moment(new Date(p.payment_period_start), 'YYYY-MM-DD HH:mm')
          .locale(this.$i18n.locale)
          .format('DD MMM YYYY HH:mm')}
                  - ${moment(new Date(p.payment_period_end), 'YYYY-MM-DD HH:mm')
          .locale(this.$i18n.locale)
          .format('DD MMM YYYY HH:mm')}`,
        payment_sum: this.currencyFormatter(p.amount, p.currency_code),
        platform_name: p.urls.map(data => ({
          icon: this.iconPlatform.find(e => e.id === (data.url_platform_id || data.platform_id))?.logo_base64,
          text: data.url,
        })),
      }));
    },

    cacheData() {
      return {
        sortings: this.sortings,
        prop: this.sorting.prop,
        order: this.sorting.order,
        search: this.search,
        status: this.status,
        needs_attention_first: this.actionRequired,
        ...this.offsetParams,
      };
    },

    isArchived() {
      return this.statusCampaign === 'archived';
    },
  },
  methods: {
    async markAction(row, action) {
      if (action) {
        await this.$api.postPaymentMarkAsRead(row.id);
        this.$store.dispatch('getCampaignsAttentionCounters');
        this.fetchData(true);
      } else {
        await this.$api.postPaymentMarkAsUnread(row.id);
        this.$store.dispatch('getCampaignsAttentionCounters');
        this.fetchData(true);
      }
    },

    handleCampaignPush(id, ids, row) {
      if (row.root_campaign_id === row.campaign_id) {
        this.$router.push(
          `/dashboard/media-buying-campaigns/${row.campaign_id}/${this.compressUrlQuery({
            ...row,
            campaign_id: row.campaign_id,
            status: '',
            period: this.period,
            activeTab: 'payments',
          })}`,
        );
      } else {
        localStorage.setItem(
          'getBackUrlCampaign',
          JSON.stringify(`/dashboard/media-buying-campaigns/${row.root_campaign_id}/${this.compressUrlQuery({
            ...row,
            campaign_id: row.root_campaign_id,
            status: '',
            needsAttention: {
              payments: row.needs_attention_payments,
              posts: row.needs_attention_posts,
              total: row.needs_attention_total,
            },
          })}`),
        );

        this.$router.push(
          `/dashboard/media-buying-campaigns-sub/${row.campaign_id}/${this.compressUrlQuery({
            ...row,
            campaign_name: row.root_campaign_name,
            campaign_id: row.campaign_id,
            status: '',
            period: this.period,
            activeTab: 'payments',
            needsAttention: {
              payments: row.needs_attention_payments,
              posts: row.needs_attention_posts,
              total: row.needs_attention_total,
            },
            // nestedSettingView,
          })}`,
        );
      }
      document.removeEventListener('mouseup', this.listener);
    },

    changePeriod() {
      this.fetchData();
    },

    async inputFilter(data) {
      this.filters = data;
      this.page = 1;
      this.update();
    },

    reset() {
      this.filters = null;
      this.update();
    },

    initFilter(query) {
      const filters = this.$_.pick(query, ['moderationStatus', 'payoutStatus']);
      const defaultFilters = this.$_.pick(defaultParams, ['moderationStatus', 'payoutStatus']);

      this.filters = {
        ...defaultFilters,
        ...this.filters,
        ...filters,
      };
    },

    getDefaultFilter() {
      return {
        moderationStatus: defaultParams.moderationStatus,
        payoutStatus: defaultParams.payoutStatus,
      };
    },

    async requestPayment(row) {
      this.$refs.requestPayment.open(row);
      if (row.needs_attention) {
        await this.markAction(row, row.needs_attention);
        this.$store.dispatch('getCampaignsAttentionCounters');
      }
    },

    update(action, silent) {
      this.$emit('update', {
        tab: 'payments',
        typeAction: action,
        prop: fieldMap[this.sorting.prop] || this.sorting.prop,
        order: this.sorting.order,
        search: this.search,
        status: this.status,
        needs_attention_first: this.actionRequired,

        moderation_status: this.filters?.moderationStatus || '',
        payout_status: this.filters?.payoutStatus || '',
        created_at_from: this.period.from,
        created_at_to: this.period.to,
        period: this.period.period,

        ...this.offsetParams,
      }, undefined, silent);
    },

    async fetchData(silent) {
      this.$nextTick(() => this.update(undefined, silent));
    },

    currencyFormatter(amount, code) {
      amount = amount.toFixed(2);
      const amountData = amount.split('.');
      const result = (+amountData[0]).toLocaleString();

      return `${this.getCurrencyLabel(code)}${result}.${amountData[1]}`;
    },

    async modifyPayment(row) {
      this.$refs.modifyPayment.open(row);
      if (row.needs_attention) {
        await this.markAction(row, row.needs_attention);
        this.$store.dispatch('getCampaignsAttentionCounters');
      }
    },
  },

  created() {
    this.$store.dispatch('getPaymentMethod', {});

    this.moderationStatus = this.getUrlQueryParam('moderation_status');
    this.payoutStatus = this.getUrlQueryParam('payout_status');

    this.actions.operations.map((e) => {
      if (e.component) {
        e.component = e.component.bind(this);
      }
    });
  },

  mounted() {
    eventBus.$on('addPayment', this.update);
  },
};
</script>

<style lang="scss">
.checkbox-label__action-required {
  white-space: nowrap;
}
</style>
