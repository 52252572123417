<template>
  <div class="profile-card profile__payment">
    <edit-payment-method ref="editPaymentMethodModal" />
    <h5 class="card-title d-flex justify-content-between align-items-center w-100 pt-0">
      <span>{{ $t("profile.paymentMethod.title") }}</span>
      <button
        class="btn btn-outline-primary btn-sm"
        @click="edit"
      >
        {{ $t("profile.buttons.edit") }}
      </button>
    </h5>
    <div class="col-12 pl-0 item-container">
      <MenuItem
        class="col-12 col-sm-6 pl-0 mt-16"
        :text="user.payment_method_name || $t('profile.generalInfo.notSpecified')"
        :type="$t('profile.paymentMethod.paymentMethod')"
        icon="fas fa-wallet"
      />
      <MenuItem
        class="col-12 col-sm-6 pl-0 mt-16"
        :text="user.payment_wallet || $t('profile.generalInfo.notSpecified')"
        :type="$t('profile.paymentMethod.walletNumber')"
        icon="fas fa-hashtag"
      />
    </div>
    <modal :show.sync="edit_forbidden" class="modal-alert profile__modal-paymet-edit">
      <h5 slot="header" class="modal-title">
        {{ $t("profile.paymentMethod.paymentMethod") }}
      </h5>
      <template>
        <p class="mb-8">
          {{ $t("profile.paymentMethod.warning") }}
        </p>
      </template>
      <div slot="footer" class="d-flex justify-content-between w-100 mt-8">
        <button class="btn btn-outline-primary btn-sm btn__back" @click="close">
          &lt; {{ $t("profile.buttons.back") }}
        </button>
        <div @click="close">
          <router-link :to="{ path: '/dashboard/contacts'}" class="btn btn-primary btn-sm btn__contact-us">
            {{ $t("profile.buttons.contactUs") }}
          </router-link>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { Modal } from 'src/components';
import MenuItem from '@/components/Form/MenuItem.vue';
import EditPaymentMethod from './Modal/EditPaymentMethod.vue';

export default {
  components: {
    EditPaymentMethod,
    MenuItem,
    Modal,
  },
  props: ['user', 'form', 'btnDisable'],
  data() {
    return {
      isLoading: false,
      edit_forbidden: false,
      errors_: {},
      currentForm: {},
    };
  },

  methods: {
    edit() {
      if (this.editDisabled) {
        this.edit_forbidden = true;
      } else if (this.form.payment_wallet
        && this.form.payment_wallet.trim()) {
        this.edit_forbidden = true;
      } else {
        this.$refs.editPaymentMethodModal.open({
          site_payment_method_id: this.form.site_payment_method_id,
          payment_wallet: this.form.payment_wallet,
          payment_method: this.user.payment_method_name,
        })
      }
    },
    close() {
      this.edit_forbidden = false;
    },
  },
  computed: {
    paymentMethods() {
      return this.$store.state.misc.paymentMethods;
    },
    editDisabled() {
      const currencyCode = this.$store.state.payments.wallets.find(wallet => wallet.status === 'active')?.currency_code
      const { max_balance_to_set_payment_wallet } = this.$store.state.settings.limitations;
      const availableBalance = parseFloat(this.$store.state.payments.balance).toFixed(2);
      return max_balance_to_set_payment_wallet !== null && currencyCode !== max_balance_to_set_payment_wallet?.currency_code || availableBalance >= max_balance_to_set_payment_wallet?.amount;
    },
  },
  beforeCreate() {
    this.$eventBus.$on('sendMessage', () => {
      this.edit_forbidden = false;
    });
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/profile/editpaymentmethod';
</style>
